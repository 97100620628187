import axios from "axios";
import { redirectFunc } from "../../utils/redirect";

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  STATUS,
  tokenKey,
  itemsKey,
  posLayoutKey,
} from "../../utils/constants";
export const getItems = createAsyncThunk(
  "get_Items",
  async (data, { fulfillWithValue, rejectWithValue }) => {
    const token = localStorage.getItem(tokenKey);
    let phoneNumber = data?.phoneNumber || "";
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LEO_URL_V2}Items/POSListAll${
          phoneNumber ? `?phoneNumber=${phoneNumber}` : ""
        }`,
        { headers: { Authorization: "Bearer " + token } }
      );

      const Items = response?.data?.data?.map((item) => {
        return { ...item, name: item?.shortName || item?.name };
      });
      localStorage.setItem(itemsKey, JSON.stringify(Items));

      return fulfillWithValue(Items);
    } catch (err) {
      redirectFunc({ ...err });

      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(error?.response?.data?.toString() || message);
    }
  }
);

export const getItemsLayout = createAsyncThunk(
  "get_Items_layout",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    const token = localStorage.getItem(tokenKey);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LEO_URL}PosLayouts/GetByCinema`,
        { headers: { Authorization: "Bearer " + token } }
      );

      const layoutData = response.data.data.serviceAreas;
      localStorage.setItem(posLayoutKey, JSON.stringify(layoutData));

      return fulfillWithValue(layoutData);
    } catch (err) {
      redirectFunc({ ...err });

      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(error?.response?.data?.toString() || message);
    }
  }
);

export const authenticateAdmin = createAsyncThunk(
  "authenticate_admin",
  async ({ userNumber, pin }, { fulfillWithValue, rejectWithValue }) => {
    const token = localStorage.getItem(tokenKey);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}Authentication/AuthenticateUser`,
        { userNumber, pin },
        { headers: { Authorization: "Bearer " + token } }
      );

      const authInfo = { ...response.data };

      return fulfillWithValue(authInfo);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      if (error.response.data === false) {
        return rejectWithValue("Incorrect Password");
      }
      if (error?.response?.data?.errors[0].errorMessages.length > 0) {
        message = error?.response?.data?.errors[0].errorMessages[0];
      }

      return rejectWithValue(message);
    }
  }
);

//2nd admin authentication that lets posprime user perform delete and abort action
export const authenticateAdmin2 = createAsyncThunk(
  "authenticate_admin2",
  async (
    { userNumber, pin, action },
    { fulfillWithValue, rejectWithValue }
  ) => {
    const token = localStorage.getItem(tokenKey);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}Authentication/AuthenticateUserByAction`,
        { userNumber, pin, action },
        { headers: { Authorization: "Bearer " + token } }
      );

      const authInfo = { ...response.data };

      return fulfillWithValue(authInfo);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      if (error.response.data === false) {
        return rejectWithValue("Incorrect Password");
      }
      if (error?.response?.data?.errors[0].errorMessages.length > 0) {
        message = error?.response?.data?.errors[0].errorMessages[0];
      }

      return rejectWithValue(message);
    }
  }
);

export const createAdminAction = createAsyncThunk(
  "create_admin_action",
  async (body, { fulfillWithValue, rejectWithValue }) => {
    const token = localStorage.getItem(tokenKey);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}AdminActions/Create`,
        { ...body },
        { headers: { Authorization: "Bearer " + token } }
      );

      const feedback = response.data.data;

      return fulfillWithValue(feedback);
    } catch (err) {
      // redirectFunc({ ...err });

      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(
        error?.response?.data?.errors[0]?.errorMessages[0]?.toString() ||
          message
      );
    }
  }
);

const initialState = {
  error: "",
  status: STATUS.IDLE,
  itemsList: localStorage.getItem(itemsKey)
    ? JSON.parse(localStorage.getItem(itemsKey))
    : [],
  posLayoutError: "",
  posLayoutStatus: STATUS.IDLE,
  posLayoutData: localStorage.getItem(posLayoutKey)
    ? JSON.parse(localStorage.getItem(posLayoutKey))
    : [],
  adminAuthError: "",
  adminAuthStatus: STATUS.IDLE,
  adminAuthInfo: null,
  adminAuthError2: "",
  adminAuthStatus2: STATUS.IDLE,
  adminAuthInfo2: null,
  adminActionError: "",
  adminActionStatus: STATUS.IDLE,
  adminAction: "",
  selectedPage: "",
};

export const itemsSlice = createSlice({
  name: "items",
  initialState,
  reducers: {
    setSelectedPage(state, action) {
      state.selectedPage = action.payload;
    },
    manualUpdateItems(state, action) {
      state.itemsList = action.payload;
      localStorage.setItem(itemsKey, JSON.stringify(action.payload));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getItems.pending, (state) => {
        state.error = "";
        state.status = STATUS.PENDING;
      })
      .addCase(getItems.fulfilled, (state, action) => {
        state.error = "";
        state.status = STATUS.RESOLVED;
        state.itemsList = action.payload;
      })
      .addCase(getItems.rejected, (state, action) => {
        state.error = action.payload;
        state.status = STATUS.REJECTED;
      })
      .addCase(getItemsLayout.pending, (state) => {
        state.posLayoutError = "";
        state.posLayoutStatus = STATUS.PENDING;
      })
      .addCase(getItemsLayout.fulfilled, (state, action) => {
        state.posLayoutError = "";
        state.posLayoutStatus = STATUS.RESOLVED;
        state.posLayoutData = action.payload;
      })
      .addCase(getItemsLayout.rejected, (state, action) => {
        state.posLayoutError = action.payload;
        state.posLayoutStatus = STATUS.REJECTED;
      })
      .addCase(authenticateAdmin.pending, (state) => {
        state.adminAuthError = "";
        state.adminAuthStatus = STATUS.PENDING;
      })
      .addCase(authenticateAdmin.fulfilled, (state, action) => {
        state.adminAuthError = "";
        state.adminAuthStatus = STATUS.RESOLVED;
        state.adminAuthInfo = action.payload;
      })
      .addCase(authenticateAdmin.rejected, (state, action) => {
        state.adminAuthError = action.payload;
        state.adminAuthStatus = STATUS.REJECTED;
      })
      .addCase(authenticateAdmin2.pending, (state) => {
        state.adminAuthError2 = "";
        state.adminAuthStatus2 = STATUS.PENDING;
      })
      .addCase(authenticateAdmin2.fulfilled, (state, action) => {
        state.adminAuthError2 = "";
        state.adminAuthStatus2 = STATUS.RESOLVED;
        state.adminAuthInfo2 = action.payload;
      })
      .addCase(authenticateAdmin2.rejected, (state, action) => {
        state.adminAuthError2 = action.payload;
        state.adminAuthStatus2 = STATUS.REJECTED;
      })
      .addCase(createAdminAction.pending, (state) => {
        state.adminActionError = "";
        state.adminActionStatus = STATUS.PENDING;
      })
      .addCase(createAdminAction.fulfilled, (state, action) => {
        state.adminActionError = "";
        state.adminActionStatus = STATUS.RESOLVED;
        state.adminAction = action.payload;
      })
      .addCase(createAdminAction.rejected, (state, action) => {
        state.adminActionError = action.payload;
        state.adminActionStatus = STATUS.REJECTED;
      });
  },
});

export const { setSelectedPage, manualUpdateItems } = itemsSlice.actions;

export const itemsReducer = itemsSlice.reducer;
