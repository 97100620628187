import { useState, useMemo, useEffect } from "react";
import { STATUS } from "../../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { addToOrder, setSelectedPage } from "../../../redux/slices";
import ConcessionModal from "./concessionModal";
import VariablePriceModal from "./VariablePriceModal";
import { sampleCustomLayout } from "../../../utils/data";
import { numToCurrency } from "../../../utils/currencyHelper";
import { useTranslation } from "react-i18next";

const Concession = ({
  serviceArea,
  serviceAreaNum,
  searchResults = [],
  itemSearchModule = false,
}) => {
  const { t } = useTranslation(["Home"]);

  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [subItems, setSubItems] = useState([]);
  const [showBatch2, setShowBatch2] = useState(false);
  const [showVariablePriceModal, setShowVariablePriceModal] = useState(false);
  const [variablePriceItem, setVariablePriceItem] = useState({});
  const { status, itemsList, posLayoutData, selectedPage } = useSelector(
    (state) => state.items
  );
  const { cinemaSettings } = useSelector((state) => state.profile);
  const [disableClose, setDisableClose] = useState(false);
  const { posLayout, posBackgroundColor } = cinemaSettings;
  const isBackgroundWhite = posBackgroundColor;
  const backgroundColor = isBackgroundWhite ? "bg-gray-300" : "bg-primary";
  const textColor = isBackgroundWhite ? "text-black" : "text-white";
  const bgHover = isBackgroundWhite
    ? "hover:bg-gray-500 hover:border-none"
    : "hover:bg-secondary";
  const textHover = isBackgroundWhite ? "hover:text-white" : "hover:text-white";

  const NewConcessionData = useMemo(() => {
    const modifiedItems = itemsList?.map((data) => {
      return { ...data, canModify: true };
    });

    const filteredItems = modifiedItems?.filter((data) => {
      return data?.serviceArea === serviceArea;
    });

    return filteredItems;
  }, [itemsList, serviceArea]);

  const [slicedArray, setSlicedArray] = useState(
    NewConcessionData.slice(0, 15)
  );

  useEffect(() => {
    if (itemSearchModule) {
      if (searchResults?.length > 0) {
        setSlicedArray(searchResults);
      } else {
        setSlicedArray([]);
      }
    }
  }, [searchResults]);

  const pages = useMemo(() => {
    if (!showBatch2) {
      return [1, 2, 3, 4, 5];
    } else {
      return [6, 7, 8, 9, 10];
    }
  }, [showBatch2]);

  const sliceArray = (page) => {
    let x = (page - 1) * 15;
    let y = page * 15;

    setSlicedArray(NewConcessionData.slice(x, y));
  };

  const customLayoutData = posLayoutData
    ?.find((x) => {
      return x.serviceArea === serviceArea;
    })
    ?.pages?.map((y) => {
      return {
        pageName: y.pageName,
        pageItems: y.pageItems
          ?.map((z) => {
            return itemsList.find((item) => {
              return item.id === z;
            });
          })
          .filter((item2) => {
            return item2 !== undefined;
          })
          .map((data) => {
            return { ...data, canModify: true };
          }),
      };
    });

  useEffect(() => {
    if (!posLayout) {
      setSlicedArray(NewConcessionData.slice(0, 15));
      dispatch(setSelectedPage(t("page", { page: 1 })));
    } else {
      if (customLayoutData?.length > 0) {
        setSlicedArray(customLayoutData[0]?.pageItems);
        dispatch(setSelectedPage(customLayoutData[0]?.pageName));
      } else {
        setSlicedArray([]);
      }
    }
  }, [posLayout, itemsList]);

  const pages2 = useMemo(() => {
    if (customLayoutData?.length <= 6) {
      return customLayoutData.map((data) => {
        return data?.pageName;
      });
    } else {
      if (!showBatch2) {
        return customLayoutData?.slice(0, 5).map((data) => {
          return data?.pageName;
        });
      } else {
        return customLayoutData?.slice(5, 10).map((data) => {
          return data?.pageName;
        });
      }
    }
  }, [customLayoutData]);
  const BtnDivs = (props) => {
    return (
      <div
        className={`w-[15%] rounded-md flex text-center items-center justify-center ${textColor} shadow-lg border-4 border-app-secondary2  ${
          props.selected && isBackgroundWhite
            ? "bg-gray-500 border-none !text-white"
            : !isBackgroundWhite && props.selected
            ? "bg-app-secondary2"
            : !props.selected && isBackgroundWhite
            ? "bg-white"
            : "bg-primary"
        } ${props.className}`}
        onClick={props.onClick}
      >
        {props.children}{" "}
      </div>
    );
  };

  const closeModal = () => {
    setIsOpen(false);
    setDisableClose(false);
  };

  const checkSubItem = (item) => {
    if (item.isPriceModifiable) {
      setShowVariablePriceModal(true);
      setVariablePriceItem(item);
      return;
    }
    if (item.itemGroup === "Single" || item.itemGroup === "Recipe") {
      dispatch(addToOrder(item));
    }

    if (item.itemGroup === "Parent") {
      setIsOpen(true);
      const formatItems = item.itemList.map((data) => {
        return { ...data, id: data.itemId, parentId: item.id, canModify: true };
      });
      setSubItems(formatItems);
    }

    if (item.itemGroup === "Package") {
      dispatch(
        addToOrder({
          id: item.id,
          price: item.price,
          name: item.name,
          idForDelete: item.id,
          isPackage: true,
          packageItemData: item,
        })
      );
      const formatItems = item.items.map((data) => {
        return {
          ...data,
          baseQuantity: data.quantity,
          orderQuantity: data.quantity,
          id: data.itemId,
          price: 0,
          priceInPackage: data.price,
          packageId: item.id,
          idForDelete: item.id,
        };
      });
      formatItems.forEach((x) => {
        dispatch(addToOrder(x));
      });

      if (item?.parentItems?.length > 0) {
        const arrayOfParentArrays1 = item.parentItems?.filter((data) => {
          return data.quantity === 1;
        });

        const arrayOfParentArrays2 = item.parentItems?.filter((data) => {
          return data.quantity !== 1;
        });

        arrayOfParentArrays2.forEach((parent) => {
          const quantityArray = Array.from({
            length: parent.quantity,
          });

          quantityArray.forEach((_, i) => {
            arrayOfParentArrays1.push(parent);
          });
        });

        const finalArray = arrayOfParentArrays1?.map((p) => {
          return p?.parent?.itemList?.map((item) => {
            return { ...item, price: 0, priceInPackage: item.price };
          });
        });

        setIsOpen(true);
        setDisableClose(true);
        setSubItems(finalArray);
      }
    }
  };

  return (
    <>
      {status === STATUS.PENDING && (
        <div
          className={` ${backgroundColor} w-[100%] h-[90%] flex items-center justify-center `}
        >
          {" "}
          <p className={textColor}>{t("loading")}</p>
        </div>
      )}

      {(status === STATUS.IDLE || status === STATUS.REJECTED) &&
        itemsList?.length < 1 && (
          <div
            className={` ${backgroundColor} w-[100%] h-[90%] flex items-center justify-center `}
          >
            {" "}
            <p className={textColor}>{t("refresh")}</p>
          </div>
        )}

      {status === STATUS.RESOLVED &&
        NewConcessionData?.length < 1 &&
        !itemSearchModule && (
          <div
            className={` ${backgroundColor} w-[100%] h-[90%] flex items-center justify-center `}
          >
            {" "}
            <p className={textColor}>{t("concessions.empty.area")}</p>
          </div>
        )}
      {status === STATUS.RESOLVED &&
        slicedArray?.length < 1 &&
        NewConcessionData?.length > 0 && (
          <div
            className={` ${backgroundColor} w-[100%] h-[90%] flex items-center justify-center `}
          >
            {" "}
            <p className={textColor}>{t("concessions.empty.page")}</p>
          </div>
        )}
      {/* {itemSearchModule && searchResults?.length < 1 && (
        <div className=" bg-primary w-[100%] h-[90%] flex items-center justify-center "></div>
      )} */}
      {((status !== STATUS.PENDING && slicedArray?.length > 0) ||
        (status !== STATUS.PENDING && itemSearchModule)) && (
        <div
          className={`${backgroundColor} h-[90%] grid grid-cols-5 grid-rows-3 gap-2 p-2`}
        >
          {slicedArray.map((item, index) => {
            return (
              <div
                key={index}
                className={`rounded shadow-lg ${isBackgroundWhite?"border-2 border-primary":""} bg-white p-2 ${bgHover} ${textHover} ${!item.imageUrl &&
                  "flex items-center justify-center"}   `}
                onClick={() => {
                  checkSubItem(item);
                }}
              >
                {item.imageUrl && cinemaSettings.includePOSImages && (
                  <div
                    className=" w-[100%] h-[65%] bg-no-repeat bg-center bg-contain"
                    style={{
                      backgroundImage: `url("${item.imageUrl}")`,
                    }}
                  ></div>
                )}
                <div
                  className={`flex justify-center  ${
                    !cinemaSettings.includePOSImages
                      ? "items-center h-[100%]"
                      : ""
                  }`}
                >
                  <div>
                    <p className="text-center uppercase text-lg font-bold">
                      {item?.name}
                    </p>
                    {item?.price ? (
                      <p className="text-center text-base font-bold ">
                        {numToCurrency(item?.price)}
                      </p>
                    ) : (
                      ""
                    )}
                    {cinemaSettings?.stockControl && item?.stock ? (
                      <p className="text-center  text-[14px] font-bold ">{`Stock: ${item?.stock}`}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}

      <div
        className={`h-[10%] flex justify-between pb-2 px-1 ${backgroundColor}`}
      >
        {!posLayout
          ? pages?.map((page, index) => {
              return (
                <BtnDivs
                  selected={selectedPage === t("page", { page })}
                  key={index}
                  onClick={() => {
                    dispatch(setSelectedPage(t("page", { page })));
                    sliceArray(page);
                  }}
                >
                  {t("page", { page })}
                </BtnDivs>
              );
            })
          : pages2?.map((page, index) => {
              return (
                <BtnDivs
                  selected={selectedPage === page}
                  key={index}
                  onClick={() => {
                    dispatch(setSelectedPage(page));
                    setSlicedArray(
                      customLayoutData.find((data) => {
                        return data.pageName === page;
                      }).pageItems
                    );
                  }}
                >
                  {page}
                </BtnDivs>
              );
            })}
        {(!posLayout || (posLayout && customLayoutData?.length > 6)) && (
          <BtnDivs
            selected={true}
            onClick={() => {
              setShowBatch2((prevState) => !prevState);
            }}
          >
            {showBatch2 ? t("button.back") : t("button.next")}
          </BtnDivs>
        )}
      </div>

      <ConcessionModal
        openStatus={isOpen}
        closeFunc={closeModal}
        data={subItems}
        disableClose={disableClose}
      />
      <VariablePriceModal
        openStatus={showVariablePriceModal}
        closeFunc={() => {
          setShowVariablePriceModal(false);
        }}
        data={variablePriceItem}
      />
    </>
  );
};

export default Concession;
