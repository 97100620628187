import axios from "axios";
import { redirectFunc } from "../../utils/redirect";

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { STATUS, tokenKey, showTimesKey } from "../../utils/constants";
import endOfDay from "date-fns/endOfDay";
import addHours from "date-fns/addHours";

export const getShowtimes = createAsyncThunk(
  "get_Showtimes",
  async (data, { fulfillWithValue, rejectWithValue }) => {
    let startDate = data?.startDate || addHours(new Date(), -4).toISOString();
    let phoneNumber = data?.phoneNumber || "";
    const token = localStorage.getItem(tokenKey);
    const endDate = endOfDay(new Date(startDate)).toISOString();
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_LEO_URL
        }Showtimes/POSListAll?DateFrom=${startDate}&DateTo=${endDate}${
          phoneNumber ? `&phoneNumber=${phoneNumber}` : ""
        }`,
        { headers: { Authorization: "Bearer " + token } }
      );

      const Showtimes = response.data.data;

      localStorage.setItem(showTimesKey, JSON.stringify(Showtimes));

      return fulfillWithValue(Showtimes);
    } catch (err) {
      redirectFunc({ ...err });

      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getFilmDetails = createAsyncThunk(
  "get_Film_Details",
  async (filmId, { fulfillWithValue, rejectWithValue }) => {
    const token = localStorage.getItem(tokenKey);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LEO_URL}Films/GetById?filmId=${filmId}`,
        { headers: { Authorization: "Bearer " + token } }
      );

      return fulfillWithValue(response.data.data);
    } catch (err) {
      redirectFunc({ ...err });

      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getSeatLayout = createAsyncThunk(
  "get_Seat_Layout",
  async (
    { showtimeId, seatClassId },
    { fulfillWithValue, rejectWithValue }
  ) => {
    const token = localStorage.getItem(tokenKey);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LEO_URL_V2}Showtimes/SeatLayout?showtimeId=${showtimeId}&seatClassId=${seatClassId}`,
        { headers: { Authorization: "Bearer " + token } }
      );

      return fulfillWithValue(response.data.data);
    } catch (err) {
      redirectFunc({ ...err });

      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

const initialState = {
  error: "",
  status: STATUS.IDLE,

  showtimesList: localStorage.getItem(showTimesKey)
    ? JSON.parse(localStorage.getItem(showTimesKey))
    : {},
  filmDetailsErr: "",
  filmDetailsStatus: STATUS.IDLE,
  filmDetails: {},
  seatLayoutErr: "",
  seatLayoutStatus: STATUS.IDLE,
  seatLayout: [],
};

export const showtimesSlice = createSlice({
  name: "showtimes",
  initialState,
  reducers: {
    manualUpdateShowtimes(state, action) {
      state.showtimesList = action.payload;
      localStorage.setItem(showTimesKey, JSON.stringify(action.payload));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getShowtimes.pending, (state) => {
        state.error = "";
        state.status = STATUS.PENDING;
      })
      .addCase(getShowtimes.fulfilled, (state, action) => {
        state.error = "";
        state.status = STATUS.RESOLVED;
        state.showtimesList = action.payload;
      })
      .addCase(getShowtimes.rejected, (state, action) => {
        state.error = action.payload;
        state.status = STATUS.REJECTED;
      })
      .addCase(getFilmDetails.pending, (state) => {
        state.filmDetailsErr = "";
        state.filmDetailsStatus = STATUS.PENDING;
      })
      .addCase(getFilmDetails.fulfilled, (state, action) => {
        state.filmDetailsErr = "";
        state.filmDetailsStatus = STATUS.RESOLVED;
        state.filmDetails = action.payload;
      })
      .addCase(getFilmDetails.rejected, (state, action) => {
        state.filmDetailsErr = action.payload;
        state.filmDetailsStatus = STATUS.REJECTED;
      })
      .addCase(getSeatLayout.pending, (state) => {
        state.seatLayoutErr = "";
        state.seatLayoutStatus = STATUS.PENDING;
      })
      .addCase(getSeatLayout.fulfilled, (state, action) => {
        state.seatLayoutErr = "";
        state.seatLayoutStatus = STATUS.RESOLVED;
        state.seatLayout = action.payload;
      })
      .addCase(getSeatLayout.rejected, (state, action) => {
        state.seatLayoutErr = action.payload;
        state.seatLayoutStatus = STATUS.REJECTED;
      });
  },
});

export const { manualUpdateShowtimes } = showtimesSlice.actions;

export const showtimesReducer = showtimesSlice.reducer;
