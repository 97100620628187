import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  STATUS,
  tokenKey,
  loginKey,
  userId,
  userDataKey,
  cinemaSettings,
  sessionId,
  claimsKey,
} from "../../utils/constants";

// axios.interceptors.response.use(response => {
//   console.log(response)
// }, (error) => {
//   console.log({error})
// })

export const userLogin = createAsyncThunk(
  "userlogin",
  async ({ userNumber, pin }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}Authentication/LoginWithPin`,
        { userNumber, pin }
      );

      const token = response.data.token;
      const id = response.data.userId;
      const sessId = response.data.sessionId;
      const claims = response.data.claimAccess;
      localStorage.setItem(tokenKey, token);
      localStorage.setItem(userId, id);
      localStorage.setItem(sessionId, sessId);
      localStorage.setItem(loginKey, new Date().getTime());
      localStorage.setItem(claimsKey, JSON.stringify(claims));

      const profileInfo = { ...response.data };

      return fulfillWithValue(profileInfo);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(error.response.data || message);
    }
  }
);
export const getUser = createAsyncThunk(
  "get_user",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    const token = localStorage.getItem(tokenKey);
    const id = localStorage.getItem(userId);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LEO_URL}Users/GetById?userId=${id}`,
        { headers: { Authorization: "Bearer " + token } }
      );

      const user = response.data.data;
      localStorage.setItem(userDataKey, JSON.stringify(user));

      return fulfillWithValue(user);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);
export const getSalesSummary = createAsyncThunk(
  "get_sales_summary",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    const token = localStorage.getItem(tokenKey);
    const id = localStorage.getItem(userId);
    const todayDate = new Date().toISOString();

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LEO_URL}Reports/GetSummaryReport?todayDate=${todayDate}&userId=${id}`,
        { headers: { Authorization: "Bearer " + token } }
      );

      const user = response.data.data;

      return fulfillWithValue(user);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getCinemaSettings = createAsyncThunk(
  "get_cinema_settings",
  async ({ cinemaId, circuitId }, { fulfillWithValue, rejectWithValue }) => {
    const token = localStorage.getItem(tokenKey);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LEO_URL}CinemaSettings/GetCinemaSetting?cinemaId=${cinemaId}&circuitId=${circuitId}`,
        { headers: { Authorization: "Bearer " + token } }
      );

      const data = response.data.data;
      localStorage.setItem(cinemaSettings, JSON.stringify(data));

      return fulfillWithValue(data);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getCashierSession = createAsyncThunk(
  "get_cashier_session",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    const token = localStorage.getItem(tokenKey);
    const userSessionId = localStorage.getItem(sessionId);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LEO_URL}Sessions/GetById?sessionId=${userSessionId}`,
        { headers: { Authorization: "Bearer " + token } }
      );

      const user = response.data.data;

      return fulfillWithValue(user);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const forgortPassword = createAsyncThunk(
  "forgot_password",
  async (userNumber, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL_V2}Users/ForgotPassword?userName=${userNumber}`
      );
      return fulfillWithValue(response?.data?.data);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);
export const resetPassword = createAsyncThunk(
  "reset_password",
  async (data, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL_V2}Users/ResetPassword`,
        data
      );
      return fulfillWithValue(response?.data?.data);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);
export const changePin = createAsyncThunk(
  "change_pin",
  async (data, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL_V2}Users/ChangePin`,
        data
      );
   
      const token = response?.data?.data?.token;
      const id = response?.data?.data?.userId;
      const sessId = response?.data?.data?.sessionId;
      const claims = response?.data?.data?.claimAccess;
      localStorage.setItem(tokenKey, token);
      localStorage.setItem(userId, id);
      localStorage.setItem(sessionId, sessId);
      localStorage.setItem(loginKey, new Date().getTime());
      localStorage.setItem(claimsKey, JSON.stringify(claims));
      return fulfillWithValue(response?.data?.data);
      
    } catch (err) {
      const error = { ...err };
   
      let message = "Unable to complete request";
    
      return rejectWithValue(
        error?.response?.data?.errors[0]?.errorMessages[0] ||
        error?.response?.data?.errors?.ResetToken[0] ||
          message
      );
    }
  }
);

const initialState = {
  error: "",
  status: STATUS.IDLE,
  info: null,
  userError: "",
  userStatus: STATUS.IDLE,
  userData: localStorage.getItem(userDataKey)
    ? JSON.parse(localStorage.getItem(userDataKey))
    : {},
  salesSummary: {},
  salesSummaryError: "",
  salesSummaryStatus: STATUS.IDLE,
  offlineMode: false,
  offlineModeRestriction: false,
  cinemaSettings: localStorage.getItem(cinemaSettings)
    ? JSON.parse(localStorage.getItem(cinemaSettings))
    : {},
  cinemaSettingsError: "",
  cinemaSettingsStatus: STATUS.IDLE,
  showKeyboard: false,
  cashierSession: {},
  cashierSessionErr: "",
  cashierSessionStatus: STATUS.IDLE,
  keyboardConfigurations: {
    type: "numeric",
    sharedInput: "",
    placeHolder: "",
    // setSharedInput: () => {},
  },
  isOnline: true,
  userClaims: localStorage.getItem(claimsKey)
    ? JSON.parse(localStorage.getItem(claimsKey))
    : {},
  forgotPwd: "",
  forgotPwdStatus: STATUS.IDLE,
  forgotPwdError: null,
  resetPwd: "",
  resetPwdStatus: STATUS.IDLE,
  resetPwdError: null,
  changePin: "",
  changePinStatus: STATUS.IDLE,
  changePinError: null,
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    logout: () => {},
    setOfflineMode(state, action) {
      state.offlineMode = !state.offlineMode;
    },
    setOfflineRestriction(state, action) {
      state.offlineModeRestriction = action.payload;
    },
    setShowKeyboard(state, action) {
      state.showKeyboard = action.payload;
    },
    setKeyboardConfiguration(state, action) {
      state.keyboardConfigurations = action.payload;
    },
    setResetPwd(state, action) {
      state.resetPwdStatus = STATUS.IDLE;
      state.resetPwdError = "";
    },
    setResetForgotPwd(state, action) {
      state.forgotPwdStatus = STATUS.IDLE;
      state.forgotPwdError = "";
    },
    setOnlineStatus(state, action) {
      state.isOnline = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.pending, (state) => {
        state.error = "";
        state.status = STATUS.PENDING;
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.error = "";
        state.status = STATUS.RESOLVED;
        state.info = action.payload;
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.error = action.payload;
        state.status = STATUS.REJECTED;
      })
      .addCase(getUser.pending, (state) => {
        state.userError = "";
        state.userStatus = STATUS.PENDING;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.userError = "";
        state.userStatus = STATUS.RESOLVED;
        state.userData = action.payload;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.userError = action.payload;
        state.userStatus = STATUS.REJECTED;
      })
      .addCase(getSalesSummary.pending, (state) => {
        state.salesSummaryError = "";
        state.salesSummaryStatus = STATUS.PENDING;
      })
      .addCase(getSalesSummary.fulfilled, (state, action) => {
        state.salesSummaryError = "";
        state.salesSummaryStatus = STATUS.RESOLVED;
        state.salesSummary = action.payload;
      })
      .addCase(getSalesSummary.rejected, (state, action) => {
        state.salesSummaryError = action.payload;
        state.salesSummaryStatus = STATUS.REJECTED;
      })
      .addCase(getCinemaSettings.pending, (state) => {
        state.cinemaSettingsError = "";
        state.cinemaSettingsStatus = STATUS.PENDING;
      })
      .addCase(getCinemaSettings.fulfilled, (state, action) => {
        state.cinemaSettingsError = "";
        state.cinemaSettingsStatus = STATUS.RESOLVED;
        state.cinemaSettings = action.payload;
      })
      .addCase(getCinemaSettings.rejected, (state, action) => {
        state.cinemaSettingsError = action.payload;
        state.cinemaSettingsStatus = STATUS.REJECTED;
      })
      .addCase(getCashierSession.pending, (state) => {
        state.cashierSessionErr = "";
        state.cashierSessionStatus = STATUS.PENDING;
      })
      .addCase(getCashierSession.fulfilled, (state, action) => {
        state.cashierSessionErr = "";
        state.cashierSessionStatus = STATUS.RESOLVED;
        state.cashierSession = action.payload;
      })
      .addCase(getCashierSession.rejected, (state, action) => {
        state.cashierSessionErr = action.payload;
        state.cashierSessionStatus = STATUS.REJECTED;
      })
      .addCase(forgortPassword.pending, (state, action) => {
        state.forgotPwdStatus = STATUS.PENDING;
        state.forgotPwdError = "";
      })
      .addCase(forgortPassword.fulfilled, (state, action) => {
        state.forgotPwdStatus = STATUS.RESOLVED;
        state.forgotPwdError = "";
        state.forgotPwd = action.payload;
      })
      .addCase(forgortPassword.rejected, (state, action) => {
        state.forgotPwdStatus = STATUS.REJECTED;
        state.forgotPwdError = action.payload;
      })
      .addCase(resetPassword.pending, (state, action) => {
        state.resetPwdStatus = STATUS.PENDING;
        state.resetPwdError = "";
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.resetPwdStatus = STATUS.RESOLVED;
        state.resetPwdError = "";
        state.resetPwd = action.payload;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.resetPwdStatus = STATUS.REJECTED;
        state.resetPwdError = action.payload;
      })
      .addCase(changePin.pending, (state, action) => {
        state.changePinStatus = STATUS.PENDING;
        state.changePinError = "";
      })
      .addCase(changePin.fulfilled, (state, action) => {
      
        state.changePinStatus = STATUS.RESOLVED;
        state.changePinError = "";
        state.changePin = action.payload;
      })
      .addCase(changePin.rejected, (state, action) => {
        state.changePinStatus = STATUS.REJECTED;
        state.changePinError = action.payload;
      });
  },
});

export const {
  logout,
  setOfflineMode,
  setOfflineRestriction,
  setShowKeyboard,
  setResetPwd,
  setKeyboardConfiguration,
  setOnlineStatus,
  setResetForgotPwd,
} = profileSlice.actions;

export const profileReducer = profileSlice.reducer;
