import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  STATUS,
  sessionId,
  tokenKey,
  customChannelsKey,
} from "../../utils/constants";
import axios from "axios";

export const createOrder = createAsyncThunk(
  "create_order",
  async (data, { fulfillWithValue, rejectWithValue }) => {
    const token = localStorage.getItem(tokenKey);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL_V2}Orders/Create`,
        { ...data },
        { headers: { Authorization: "Bearer " + token } }
      );

      const order = response.data.data;

      return fulfillWithValue(order);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      return rejectWithValue(
        error?.response?.data?.errors?.[0]?.errorMessages?.[0] || message
      );
    }
  }
);
export const completeCreatedOrder = createAsyncThunk(
  "complete_created_order",
  async (orderId, { fulfillWithValue, rejectWithValue }) => {
    const token = localStorage.getItem(tokenKey);

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}Orders/${orderId}/Complete`,
        {},
        { headers: { Authorization: "Bearer " + token } }
      );

      const order = response.data.data;

      return fulfillWithValue(order);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);
export const createReservation = createAsyncThunk(
  "create_reservation",
  async (data, { fulfillWithValue, rejectWithValue }) => {
    const token = localStorage.getItem(tokenKey);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}Bookings/CreateReservation`,
        { ...data },
        { headers: { Authorization: "Bearer " + token } }
      );

      const reservationResp = response.data.data;

      return fulfillWithValue(reservationResp);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      return rejectWithValue(
        error?.response?.data?.errors?.[0]?.errorMessages?.[0] || message
      );
    }
  }
);

export const completeCreatedReservation = createAsyncThunk(
  "complete_created_reservation",
  async (data, { fulfillWithValue, rejectWithValue }) => {
    const token = localStorage.getItem(tokenKey);
    const { bookingId, ...body } = data;

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}Bookings/CompleteReservation?bookingId=${bookingId}`,
        body?.orders,
        { headers: { Authorization: "Bearer " + token } }
      );

      const order = response.data.data;

      return fulfillWithValue(order);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getSalesReport = createAsyncThunk(
  "get_sales_report",
  async (userId, { fulfillWithValue, rejectWithValue }) => {
    const token = localStorage.getItem(tokenKey);

    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_LEO_URL
        }Reports/GetSalesReportByUser?todayDate=${new Date().toISOString()}&userId=${userId}`,
        { headers: { Authorization: "Bearer " + token } }
      );

      const salesReport = response.data.data;

      return fulfillWithValue(salesReport);
    } catch (err) {
      // redirectFunc({ ...err });

      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(error?.response?.data?.toString() || message);
    }
  }
);
export const getBooking = createAsyncThunk(
  "get_booking",
  async (
    { bookingId, email, phoneNumber },
    { fulfillWithValue, rejectWithValue }
  ) => {
    const token = localStorage.getItem(tokenKey);

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL_V2}Bookings/Redeem?${
          bookingId ? `bookingId=${bookingId}` : ""
        }${email ? `${bookingId ? "&" : ""}email=${email}` : ""}${
          phoneNumber
            ? `${bookingId || email ? "&" : ""}phoneNumber=${phoneNumber}`
            : ""
        }`,
        {},
        { headers: { Authorization: "Bearer " + token } }
      );

      const bookingData = response.data.data;

      return fulfillWithValue(bookingData);
    } catch (err) {
      // redirectFunc({ ...err });

      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(
        error?.response?.data?.errors[0].errorMessages?.toString() || message
      );
    }
  }
);
export const completeBooking = createAsyncThunk(
  "complete_booking",
  async (bookingId, { fulfillWithValue, rejectWithValue }) => {
    const token = localStorage.getItem(tokenKey);

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}Bookings/Complete?bookingId=${bookingId}`,
        {},
        { headers: { Authorization: "Bearer " + token } }
      );

      const bookingData = response.data.data;

      return fulfillWithValue(bookingData);
    } catch (err) {
      // redirectFunc({ ...err });

      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const createOfflineOrder = createAsyncThunk(
  "create_offline_order",
  async (data, { fulfillWithValue, rejectWithValue }) => {
    const token = localStorage.getItem(tokenKey);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}Orders/BulkUpload`,
        data,
        { headers: { Authorization: "Bearer " + token } }
      );
      const order = response.data.data;

      return fulfillWithValue(order);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      return rejectWithValue(
        error?.response?.data?.errors?.[0]?.errorMessages[0] || message
      );
    }
  }
);

export const getStaff = createAsyncThunk(
  "get_staff",
  async (cinemaId, { fulfillWithValue, rejectWithValue }) => {
    const token = localStorage.getItem(tokenKey);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LEO_URL}Users/GetByCinema?cinemaId=${cinemaId}`,
        { headers: { Authorization: "Bearer " + token } }
      );

      const staffData = response.data.data;

      return fulfillWithValue(staffData);
    } catch (err) {
      // redirectFunc({ ...err });

      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(
        error?.response?.data?.errors[0].errorMessages?.toString() || message
      );
    }
  }
);

export const getSalesByUser = createAsyncThunk(
  "get_sales_by_user",
  async ({ todayDate, userId }, { fulfillWithValue, rejectWithValue }) => {
    const token = localStorage.getItem(tokenKey);

    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_LEO_URL
        }Orders/ListAllByUser?todayDate=${todayDate}&userId=${userId}&page=${1}&perPage=${1000}`,
        { headers: { Authorization: "Bearer " + token } }
      );

      const userSales = response.data.data;

      return fulfillWithValue(userSales);
    } catch (err) {
      // redirectFunc({ ...err });

      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(
        error?.response?.data?.errors[0].errorMessages?.toString() || message
      );
    }
  }
);

export const getOrderById = createAsyncThunk(
  "get_order_by_Id",
  async (orderId, { fulfillWithValue, rejectWithValue }) => {
    const token = localStorage.getItem(tokenKey);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LEO_URL}Orders/${orderId}`,
        { headers: { Authorization: "Bearer " + token } }
      );

      const singleOrder = response.data.data;

      return fulfillWithValue(singleOrder);
    } catch (err) {
      // redirectFunc({ ...err });

      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(
        error?.response?.data?.errors[0].errorMessages?.toString() || message
      );
    }
  }
);

export const fetchTax = createAsyncThunk(
  "fetch_tax",
  async (
    { cinemaId, orderId, amount },
    { fulfillWithValue, rejectWithValue }
  ) => {
    const token = localStorage.getItem(tokenKey);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL_V2}Orders/FetchTax?cinemaId=${cinemaId}&orderId=${orderId}&amount=${amount}`,
        {},
        { headers: { Authorization: "Bearer " + token } }
      );

      const taxDetails = response.data.data;

      return fulfillWithValue(taxDetails);
    } catch (err) {
      // redirectFunc({ ...err });

      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(error?.response?.data?.toString() || message);
    }
  }
);

export const initiateFullRefund = createAsyncThunk(
  "initiate_full_refund",
  async ({ reason = null, orderId }, { fulfillWithValue, rejectWithValue }) => {
    const token = localStorage.getItem(tokenKey);

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}Orders/POSRefundOrder?orderId=${orderId}`,
        { reason, supplimentaryOrderId: null },
        { headers: { Authorization: "Bearer " + token } }
      );

      const order = response.data.data;

      return fulfillWithValue(order);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const initiatePartialRefund = createAsyncThunk(
  "initiate_partial_refund",
  async ({ orderId, ...data }, { fulfillWithValue, rejectWithValue }) => {
    const token = localStorage.getItem(tokenKey);

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}Orders/POSPartialRefundOrder?orderId=${orderId}`,
        { ...data, supplimentaryOrderId: null },
        { headers: { Authorization: "Bearer " + token } }
      );

      const order = response.data.data;

      return fulfillWithValue(order);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const closeSession = createAsyncThunk(
  "close_session",
  async (data, { fulfillWithValue, rejectWithValue }) => {
    const token = localStorage.getItem(tokenKey);
    const userSessionId = localStorage.getItem(sessionId);

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}Sessions/POSCloseSession`,
        { sessionId: userSessionId, ...data },
        { headers: { Authorization: "Bearer " + token } }
      );

      const order = response.data.data;

      return fulfillWithValue(order);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const reprintOrder = createAsyncThunk(
  "reprint_order",
  async ({ orderId, ...data }, { fulfillWithValue, rejectWithValue }) => {
    const token = localStorage.getItem(tokenKey);

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}Orders/ReprintOrder?orderId=${orderId}`,
        { ...data },
        { headers: { Authorization: "Bearer " + token } }
      );

      const order = response.data.data;

      return fulfillWithValue(order);
    } catch (err) {
      const error = { ...err };
      let message = "Unable to complete request";
      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getInScreenOrders = createAsyncThunk(
  "get_InScreen_Orders",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    const token = localStorage.getItem(tokenKey);

    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_LEO_URL
        }Bookings/GetInScreenBookings?todayDate=${new Date().toISOString()}`,
        { headers: { Authorization: "Bearer " + token } }
      );

      const bookings = response.data.data;

      return fulfillWithValue(bookings);
    } catch (err) {
      // redirectFunc({ ...err });

      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(
        error?.response?.data?.errors[0].errorMessages?.toString() || message
      );
    }
  }
);

export const getInScreenOrdersCount = createAsyncThunk(
  "get_InScreen_Orders_Count",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    const token = localStorage.getItem(tokenKey);

    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_LEO_URL
        }Bookings/GetInScreenBookingCount?todayDate=${new Date().toISOString()}`,
        { headers: { Authorization: "Bearer " + token } }
      );

      const bookings = response.data.data;

      return fulfillWithValue(bookings);
    } catch (err) {
      // redirectFunc({ ...err });

      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(
        error?.response?.data?.errors[0].errorMessages?.toString() || message
      );
    }
  }
);

export const getCustomChannels = createAsyncThunk(
  "get_custom_channels",
  async (cinemaId, { fulfillWithValue, rejectWithValue }) => {
    const token = localStorage.getItem(tokenKey);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LEO_URL}OtherChannels/ListAll?cinemaId=${cinemaId}`,
        { headers: { Authorization: "Bearer " + token } }
      );

      const otherChannels = response.data.data;

      return fulfillWithValue(otherChannels);
    } catch (err) {
      // redirectFunc({ ...err });

      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(
        error?.response?.data?.errors[0].errorMessages?.toString() || message
      );
    }
  }
);

export const swapTicket = createAsyncThunk(
  "swap_ticket",
  async (data, { fulfillWithValue, rejectWithValue }) => {
    const token = localStorage.getItem(tokenKey);

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}Orders/SwapTicket`,
        { ...data },
        { headers: { Authorization: "Bearer " + token } }
      );

      const swapData = response.data.data;

      return fulfillWithValue(swapData);
    } catch (err) {
      // redirectFunc({ ...err });

      const error = { ...err };
      let message = "Unable to swap ticket";

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

const initialState = {
  orderItems: localStorage.getItem("orderItems")
    ? JSON.parse(localStorage.getItem("orderItems"))
    : [],
  heldOrders: localStorage.getItem("heldOrders")
    ? JSON.parse(localStorage.getItem("heldOrders"))
    : [],
  orderTotalQuantity: 0,
  orderTotalAmount: 0,
  orderHeld: JSON.parse(localStorage.getItem("heldOrders"))?.length > 0,
  createOrderError: "",
  createOrderStatus: STATUS.IDLE,
  createdOrderId: "",
  orderError: false,
  salesReportError: "",
  salesReportStatus: STATUS.IDLE,
  salesReport: [],
  getBookingError: "",
  getBookingStatus: STATUS.IDLE,
  bookingDetails: [],
  completeBookingError: "",
  completeBookingStatus: STATUS.IDLE,
  completedBookingId: "",
  printLastOrder: false,
  createOfflineOrderError: "",
  createOfflineOrderStatus: STATUS.IDLE,
  createdOfflineOrder: {},
  allStaff: [],
  allStaffErr: "",
  allStaffStatus: STATUS.IDLE,
  userSales: [],
  userSalesErr: "",
  userSalesStatus: STATUS.IDLE,
  singleOrder: [],
  singleOrderErr: "",
  singleOrderStatus: STATUS.IDLE,
  completeCreatedOrderErr: "",
  completeCreatedOrderStatus: STATUS.IDLE,
  hasPrinted: "",
  taxData: [],
  taxDataErr: "",
  taxDataStatus: STATUS.IDLE,
  loyaltyData: {},
  fullRefundErr: "",
  fullRefundStatus: STATUS.IDLE,
  closeSessionErr: "",
  closeSessionStatus: STATUS.IDLE,
  partialRefundErr: "",
  partialRefundStatus: STATUS.IDLE,
  reprintOrderErr: "",
  reprintOrderStatus: STATUS.IDLE,
  inScreenOrders: [],
  inScreenOrdersErr: "",
  inScreenOrdersStatus: STATUS.IDLE,
  inScreenOrdersCount: 0,
  inScreenOrdersCountErr: "",
  inScreenOrdersCountStatus: STATUS.IDLE,
  customChannels: localStorage.getItem(customChannelsKey)
    ? JSON.parse(localStorage.getItem(customChannelsKey))
    : [],
  customChannelsErr: "",
  customChannelsStatus: STATUS.IDLE,
  swapResponse: "",
  swapErr: "",
  swapStatus: STATUS.IDLE,
  createReservationError: "",
  createReservationStatus: STATUS.IDLE,
  completeReservationError: "",
  completeReservationStatus: STATUS.IDLE,
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    addToOrder(state, action) {
      const existingIndex = state.orderItems.findIndex(
        (item) =>
          item.id === action.payload.id &&
          item.packageId === action.payload.packageId
      );

      const existingIndex2 = state.orderItems.findIndex(
        (item) =>
          item.id === action.payload.id &&
          item.showtimeId === action.payload.showtimeId
      );

      //if check for tickets
      if (action.payload.showtimeId) {
        //the if checks here try to determine if a ticket in the cart already exists so it would know if it should add it newly or
        //increment the quantity of the existingg ticket..to check if it already exists it checks the id,showtimeId, price and vouchercode
        if (
          existingIndex2 >= 0 &&
          state.orderItems[existingIndex2].price === action.payload.price &&
          state.orderItems[existingIndex2].showtimeId ===
            action.payload.showtimeId &&
          state.orderItems[existingIndex2].voucherCodeId ===
            action.payload.voucherCodeId &&
          state.orderItems[existingIndex2].priceInPackage ===
            action.payload.priceInPackage &&
          state.orderItems[existingIndex2].packageTicketId ===
            action.payload.packageTicketId &&
          state?.orderItems[existingIndex2]?.seatLayoutId ===
            action?.payload?.seatLayoutId &&
          state?.orderItems[existingIndex2]?.seatNumber ===
            action?.payload?.seatNumber
        ) {
          //check to ensure order cannot be increased beyond available quantity
          if (
            state.orderItems[existingIndex2].availableQuantity !== undefined &&
            state.orderItems[existingIndex2].availableQuantity <=
              state.orderItems[existingIndex2].orderQuantity
          ) {
            state.orderError =
              "Order Quantity cannot be increased. Maximum quantity has been reached";
            return;
          }

          state.orderItems[existingIndex2] = {
            ...state.orderItems[existingIndex2],
            //using this base quantity so it does not just increment by just 1 by default incase there is a predefined quantity
            orderQuantity: state.orderItems[existingIndex2].baseQuantity
              ? state.orderItems[existingIndex2].orderQuantity +
                state.orderItems[existingIndex2].baseQuantity
              : state.orderItems[existingIndex2].orderQuantity + 1,
          };
        } else {
          let tempProductItem = { ...action.payload };
          if (action.payload.orderQuantity === undefined) {
            tempProductItem = {
              ...action.payload,
              orderQuantity: action.payload.numOfSeats || 1,
            };
          }
          state.orderItems.push(tempProductItem);
        }
      }

      //if check for concessions

      if (!action.payload.showtimeId) {
        if (
          existingIndex >= 0 &&
          state.orderItems[existingIndex].price === action.payload.price &&
          state.orderItems[existingIndex].packageId ===
            action.payload.packageId &&
          state.orderItems[existingIndex].pkgShowtimeId ===
            action.payload.pkgShowtimeId &&
          state.orderItems[existingIndex].packageTicketId ===
            action.payload.packageTicketId
        ) {
          //check to ensure order cannot be increased beyond available quantity
          if (
            state.orderItems[existingIndex].availableQuantity !== undefined &&
            state.orderItems[existingIndex].availableQuantity <=
              state.orderItems[existingIndex].orderQuantity
          ) {
            state.orderError =
              "Order Quantity cannot be increased. Maximum quantity has been reached";
            return;
          }
          state.orderItems[existingIndex] = {
            ...state.orderItems[existingIndex],
            orderQuantity: state.orderItems[existingIndex].baseQuantity
              ? state.orderItems[existingIndex].orderQuantity +
                state.orderItems[existingIndex].baseQuantity
              : state.orderItems[existingIndex].orderQuantity + 1,
          };
        } else {
          let tempProductItem = { ...action.payload };
          if (action.payload.orderQuantity === undefined) {
            tempProductItem = {
              ...action.payload,
              orderQuantity: action.payload.numOfSeats || 1,
            };
          }
          state.orderItems.push(tempProductItem);
        }
      }
      localStorage.setItem("orderItems", JSON.stringify(state.orderItems));
    },
    changeOrderQuantity(state, action) {
      const itemIndex = state.orderItems.findIndex(
        (item) =>
          item.id === action.payload.id && item.price === action.payload.price
      );

      state.orderItems[itemIndex].orderQuantity = action.payload.quantity;

      localStorage.setItem("orderItems", JSON.stringify(state.orderItems));
    },
    changeQuantityForPkg(state, action) {
      state.orderItems[action.payload.index].orderQuantity =
        action.payload.quantity;

      localStorage.setItem("orderItems", JSON.stringify(state.orderItems));
    },
    decreaseOrder(state, action) {
      const itemIndex = state.orderItems.findIndex(
        (item) => item.id === action.payload.id
      );

      if (state.orderItems[itemIndex].orderQuantity > 1) {
        state.orderItems[itemIndex].orderQuantity -= 1;
      }
      // else if (state.orderItems[itemIndex].orderQuantity === 1) {
      //   const nextorderItems = state.orderItems.filter(
      //     (item) => item.id !== action.payload.id
      //   );

      //   state.orderItems = nextorderItems;
      // }

      localStorage.setItem("orderItems", JSON.stringify(state.orderItems));
    },
    removeFromOrder(state, action) {
      if (action.payload.idForDelete) {
        state.orderItems = [];
        localStorage.removeItem("orderItems");
        return;
      }
      const newOrderItem = state.orderItems.filter(
        (item) => item.id !== action.payload.id
      );
      state.orderItems = newOrderItem;
      localStorage.setItem("orderItems", JSON.stringify(state.orderItems));
      // state.orderItems.map((orderItem) => {
      //   if (orderItem.id === action.payload.id) {
      //     const nextorderItems = state.orderItems.filter(
      //       (item) => item.id !== orderItem.id
      //     );

      //     state.orderItems = nextorderItems;
      //   }
      //   localStorage.setItem("orderItems", JSON.stringify(state.orderItems));
      //   return state;
      // });
    },
    getTotals(state, action) {
      let { total, quantity } = state.orderItems.reduce(
        (orderTotal, orderItem) => {
          const { price, orderQuantity } = orderItem;
          const itemTotal = price * orderQuantity;

          orderTotal.total += itemTotal;
          orderTotal.quantity += orderQuantity;

          return orderTotal;
        },
        {
          total: 0,
          quantity: 0,
        }
      );
      total = parseFloat(total.toFixed(2));
      state.orderTotalQuantity = quantity;
      state.orderTotalAmount = total;
    },
    clearOrder(state, action) {
      state.orderItems = [];
      localStorage.removeItem("orderItems");
      // localStorage.setItem("orderItems", JSON.stringify(state.orderItems));
    },
    holdOrder(state, action) {
      if (localStorage.getItem("heldOrders")) {
        let prevHeldOrders = JSON.parse(localStorage.getItem("heldOrders"));
        let newHeldOrders = [
          { clientName: action.payload, order: state.orderItems },
          ...prevHeldOrders,
        ];
        localStorage.setItem("heldOrders", JSON.stringify(newHeldOrders));
        state.heldOrders = newHeldOrders;
        state.orderItems = [];
        localStorage.removeItem("orderItems");
      } else {
        let newHeldOrder = [
          { clientName: action.payload, order: state.orderItems },
        ];
        localStorage.setItem("heldOrders", JSON.stringify(newHeldOrder));
        state.heldOrders = newHeldOrder;
        state.orderItems = [];
        localStorage.removeItem("orderItems");
      }
    },
    clearHeldOrders(state, action) {
      state.heldOrders = [];
      localStorage.removeItem("heldOrders");
    },
    retrieveHeldOrder(state, action) {
      let heldOrders = JSON.parse(localStorage.getItem("heldOrders"));
      let selectedOrder = heldOrders.find((x) => {
        return x?.clientName.toLowerCase() === action.payload.toLowerCase();
      });
      state.orderItems = selectedOrder.order;
      let newHeldOrders = heldOrders.filter((x) => {
        return x?.clientName.toLowerCase() !== action.payload.toLowerCase();
      });
      localStorage.setItem("heldOrders", JSON.stringify(newHeldOrders));
      state.heldOrders = newHeldOrders;
    },
    deleteHeldOrder(state, action) {
      let heldOrders = JSON.parse(localStorage.getItem("heldOrders"));
      let newHeldOrders = heldOrders.filter((x) => {
        return x?.clientName.toLowerCase() !== action.payload.toLowerCase();
      });
      localStorage.setItem("heldOrders", JSON.stringify(newHeldOrders));
      state.heldOrders = newHeldOrders;
    },
    resetOrderError(state, action) {
      state.orderError = false;
    },
    setOrderError(state, action) {
      state.orderError = action.payload.error;
    },
    resetIds(state, action) {
      if (state.createdOrderId || state.completedBookingId) {
        localStorage.setItem(
          "lastOrderId",
          JSON.stringify(state.createdOrderId || state.completedBookingId)
        );
      }
      state.completedBookingId = "";
      state.createdOrderId = "";
      state.taxData = [];
    },
    setPrintLastOrder(state, action) {
      state.printLastOrder = action.payload;
    },
    setHasPrinted(state, action) {
      state.hasPrinted = action.payload;
    },
    setLoyaltyData(state, action) {
      state.loyaltyData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOrder.pending, (state) => {
        state.createOrderError = "";
        state.createOrderStatus = STATUS.PENDING;
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        state.createOrderError = "";
        state.createOrderStatus = STATUS.RESOLVED;
        state.createdOrderId = action.payload;
      })
      .addCase(createOrder.rejected, (state, action) => {
        state.createOrderError = action.payload;
        state.createOrderStatus = STATUS.REJECTED;
      })
      .addCase(getSalesReport.pending, (state) => {
        state.salesReportError = "";
        state.salesReportStatus = STATUS.PENDING;
      })
      .addCase(getSalesReport.fulfilled, (state, action) => {
        state.salesReportError = "";
        state.salesReportStatus = STATUS.RESOLVED;
        state.salesReport = action.payload;
      })
      .addCase(getSalesReport.rejected, (state, action) => {
        state.salesReportError = action.payload;
        state.salesReportStatus = STATUS.REJECTED;
      })
      .addCase(getBooking.pending, (state) => {
        state.getBookingError = "";
        state.getBookingStatus = STATUS.PENDING;
      })
      .addCase(getBooking.fulfilled, (state, action) => {
        state.getBookingError = "";
        state.getBookingStatus = STATUS.RESOLVED;
        state.bookingDetails = action.payload;
      })
      .addCase(getBooking.rejected, (state, action) => {
        state.getBookingError = action.payload;
        state.getBookingStatus = STATUS.REJECTED;
      })
      .addCase(completeBooking.pending, (state) => {
        state.completeBookingError = "";
        state.completeBookingStatus = STATUS.PENDING;
      })
      .addCase(completeBooking.fulfilled, (state, action) => {
        state.completeBookingError = "";
        state.completeBookingStatus = STATUS.RESOLVED;
        state.completedBookingId = action.payload;
      })
      .addCase(completeBooking.rejected, (state, action) => {
        state.completeBookingError = action.payload;
        state.completeBookingStatus = STATUS.REJECTED;
      })
      .addCase(createOfflineOrder.pending, (state) => {
        state.createOfflineOrderError = "";
        state.createOfflineOrderStatus = STATUS.PENDING;
      })
      .addCase(createOfflineOrder.fulfilled, (state, action) => {
        state.createOfflineOrderError = "";
        state.createOfflineOrderStatus = STATUS.RESOLVED;
        state.createdOfflineOrder = action.payload;
      })
      .addCase(createOfflineOrder.rejected, (state, action) => {
        state.createOfflineOrderError = action.payload;
        state.createOfflineOrderStatus = STATUS.REJECTED;
      })
      .addCase(getStaff.pending, (state) => {
        state.allStaffErr = "";
        state.allStaffStatus = STATUS.PENDING;
      })
      .addCase(getStaff.fulfilled, (state, action) => {
        state.allStaffErr = "";
        state.allStaffStatus = STATUS.RESOLVED;
        state.allStaff = action.payload;
      })
      .addCase(getStaff.rejected, (state, action) => {
        state.allStaffErr = action.payload;
        state.allStaffStatus = STATUS.REJECTED;
      })
      .addCase(getSalesByUser.pending, (state) => {
        state.userSalesErr = "";
        state.userSalesStatus = STATUS.PENDING;
      })
      .addCase(getSalesByUser.fulfilled, (state, action) => {
        state.userSalesErr = "";
        state.userSalesStatus = STATUS.RESOLVED;
        state.userSales = action.payload;
      })
      .addCase(getSalesByUser.rejected, (state, action) => {
        state.userSalesErr = action.payload;
        state.userSalesStatus = STATUS.REJECTED;
      })
      .addCase(getOrderById.pending, (state) => {
        state.singleOrderErr = "";
        state.singleOrderStatus = STATUS.PENDING;
      })
      .addCase(getOrderById.fulfilled, (state, action) => {
        state.singleOrderErr = "";
        state.singleOrderStatus = STATUS.RESOLVED;
        state.singleOrder = action.payload;
      })
      .addCase(getOrderById.rejected, (state, action) => {
        state.singleOrderErr = action.payload;
        state.singleOrderStatus = STATUS.REJECTED;
      })
      .addCase(completeCreatedOrder.pending, (state) => {
        state.completeCreatedOrderErr = "";
        state.completeCreatedOrderStatus = STATUS.PENDING;
      })
      .addCase(completeCreatedOrder.fulfilled, (state, action) => {
        state.completeCreatedOrderErr = "";
        state.completeCreatedOrderStatus = STATUS.RESOLVED;
      })
      .addCase(completeCreatedOrder.rejected, (state, action) => {
        state.completeCreatedOrderErr = action.payload;
        state.completeCreatedOrderStatus = STATUS.REJECTED;
      })
      .addCase(fetchTax.pending, (state) => {
        state.taxDataErr = "";
        state.taxDataStatus = STATUS.PENDING;
      })
      .addCase(fetchTax.fulfilled, (state, action) => {
        state.taxDataErr = "";
        state.taxDataStatus = STATUS.RESOLVED;
        state.taxData = action.payload;
      })
      .addCase(fetchTax.rejected, (state, action) => {
        state.taxDataErr = action.payload;
        state.taxDataStatus = STATUS.REJECTED;
      })
      .addCase(initiateFullRefund.pending, (state) => {
        state.fullRefundErr = "";
        state.fullRefundStatus = STATUS.PENDING;
      })
      .addCase(initiateFullRefund.fulfilled, (state, action) => {
        state.fullRefundErr = "";
        state.fullRefundStatus = STATUS.RESOLVED;
      })
      .addCase(initiateFullRefund.rejected, (state, action) => {
        state.fullRefundErr = action.payload;
        state.fullRefundStatus = STATUS.REJECTED;
      })
      .addCase(closeSession.pending, (state) => {
        state.closeSessionErr = "";
        state.closeSessionStatus = STATUS.PENDING;
      })
      .addCase(closeSession.fulfilled, (state, action) => {
        state.closeSessionErr = "";
        state.closeSessionStatus = STATUS.RESOLVED;
      })
      .addCase(closeSession.rejected, (state, action) => {
        state.closeSessionErr = action.payload;
        state.closeSessionStatus = STATUS.REJECTED;
      })
      .addCase(initiatePartialRefund.pending, (state) => {
        state.partialRefundErr = "";
        state.partialRefundStatus = STATUS.PENDING;
      })
      .addCase(initiatePartialRefund.fulfilled, (state, action) => {
        state.partialRefundErr = "";
        state.partialRefundStatus = STATUS.RESOLVED;
      })
      .addCase(initiatePartialRefund.rejected, (state, action) => {
        state.partialRefundErr = action.payload;
        state.partialRefundStatus = STATUS.REJECTED;
      })
      .addCase(reprintOrder.pending, (state) => {
        state.reprintOrderErr = "";
        state.reprintOrderStatus = STATUS.PENDING;
      })
      .addCase(reprintOrder.fulfilled, (state, action) => {
        state.reprintOrderErr = "";
        state.reprintOrderStatus = STATUS.RESOLVED;
      })
      .addCase(reprintOrder.rejected, (state, action) => {
        state.reprintOrderErr = action.payload;
        state.reprintOrderStatus = STATUS.REJECTED;
      })
      .addCase(getInScreenOrders.pending, (state) => {
        state.inScreenOrdersErr = "";
        state.inScreenOrdersStatus = STATUS.PENDING;
      })
      .addCase(getInScreenOrders.fulfilled, (state, action) => {
        state.inScreenOrders = action.payload;
        state.inScreenOrdersStatus = STATUS.RESOLVED;
      })
      .addCase(getInScreenOrders.rejected, (state, action) => {
        state.inScreenOrdersErr = action.payload;
        state.inScreenOrdersStatus = STATUS.REJECTED;
      })
      .addCase(getInScreenOrdersCount.pending, (state) => {
        state.inScreenOrdersCountErr = "";
        state.inScreenOrdersCountStatus = STATUS.PENDING;
      })
      .addCase(getInScreenOrdersCount.fulfilled, (state, action) => {
        state.inScreenOrdersCount = action.payload;
        state.inScreenOrdersCountStatus = STATUS.RESOLVED;
      })
      .addCase(getInScreenOrdersCount.rejected, (state, action) => {
        state.inScreenOrdersCountErr = action.payload;
        state.inScreenOrdersCountStatus = STATUS.REJECTED;
      })
      .addCase(getCustomChannels.pending, (state) => {
        state.customChannelsErr = "";
        state.customChannelsStatus = STATUS.PENDING;
      })
      .addCase(getCustomChannels.fulfilled, (state, action) => {
        state.customChannels = action.payload;
        state.customChannelsStatus = STATUS.RESOLVED;
      })
      .addCase(getCustomChannels.rejected, (state, action) => {
        state.customChannelsErr = action.payload;
        state.customChannelsStatus = STATUS.REJECTED;
      })
      .addCase(swapTicket.pending, (state) => {
        state.swapErr = "";
        state.swapStatus = STATUS.PENDING;
      })
      .addCase(swapTicket.fulfilled, (state, action) => {
        state.swapResponse = action.payload;
        state.swapStatus = STATUS.RESOLVED;
      })
      .addCase(swapTicket.rejected, (state, action) => {
        state.swapErr = action.payload;
        state.swapStatus = STATUS.REJECTED;
      })
      .addCase(createReservation.pending, (state) => {
        state.createReservationError = "";
        state.createReservationStatus = STATUS.PENDING;
      })
      .addCase(createReservation.fulfilled, (state, action) => {
        state.createReservationError = "";
        state.createReservationStatus = STATUS.RESOLVED;
      })
      .addCase(createReservation.rejected, (state, action) => {
        state.createReservationError = action.payload;
        state.createReservationStatus = STATUS.REJECTED;
      })
      .addCase(completeCreatedReservation.pending, (state) => {
        state.completeReservationError = "";
        state.completeReservationStatus = STATUS.PENDING;
      })
      .addCase(completeCreatedReservation.fulfilled, (state, action) => {
        state.completeReservationError = "";
        state.completeReservationStatus = STATUS.RESOLVED;
      })
      .addCase(completeCreatedReservation.rejected, (state, action) => {
        state.completeReservationError = action.payload;
        state.completeReservationStatus = STATUS.REJECTED;
      });
  },
});

export const {
  addToOrder,
  decreaseOrder,
  removeFromOrder,
  getTotals,
  clearOrder,
  holdOrder,
  changeOrderQuantity,
  changeQuantityForPkg,
  resetOrderError,
  setOrderError,
  resetIds,
  setPrintLastOrder,
  clearHeldOrders,
  retrieveHeldOrder,
  deleteHeldOrder,
  setHasPrinted,
  setLoyaltyData,
} = orderSlice.actions;

export default orderSlice.reducer;
