import React, { useState } from "react";

import Pop1 from "../../../assets/images/pop1.png";
import Pop2 from "../../../assets/images/pop2.png";
import Tick1 from "../../../assets/images/tick1.png";
import Tick2 from "../../../assets/images/tick2.png";
import Ticket from "./ticket";
import Concession from "./concession";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation(["Home"]);
  const [module, setModule] = useState("concessions");
  const { showtimesList, status } = useSelector((state) => state.showtimes);
  const { cinemaSettings } = useSelector((state) => state.profile);
  const isBackgroundWhite = cinemaSettings?.posBackgroundColor;
  const concessionBackGroundColor =
    module === "concessions" && isBackgroundWhite
      ? "bg-gray-300 text-primary"
      : "bg-primary text-white";
  const ticketBackGroundColor =
    module === "tickets" && isBackgroundWhite
      ? "bg-gray-300 text-primary"
      : "bg-primary text-white";
  const concessionIcon =
    isBackgroundWhite && module === "concessions"
      ? Pop1
      : isBackgroundWhite && module === "tickets"
      ? Pop1
      : !isBackgroundWhite && module === "tickets"
      ? Pop1
      : Pop2;
  const ticketIcon =
    isBackgroundWhite && module === "tickets"
      ? Tick1
      : isBackgroundWhite && module === "concessions"
      ? Tick1
      : !isBackgroundWhite && module === "tickets"
      ? Tick2
      : Tick1;
  // const textColor =
  const showtimesDate =
    new Date(showtimesList?.paramFilter?.dateTo||new Date()).toDateString()
  
  // #F3F4F6
  // #E7F0FA
  // #FAF4E6
  // #E0F7F4
  // #EAF4EA
  return (
    <>
      <div className="flex justify-center h-[8%]">
        <div
          className={`w-[40%] shadow-lg ${isBackgroundWhite&&module!=="concessions"?"border border-gray-500":""} text-lg flex items-center px-2 ${
            module === "concessions"
              ? concessionBackGroundColor
              : "text-primary"
          }`}
          onClick={() => {
            setModule("concessions");
          }}
        >
          <img src={concessionIcon} alt="popcorn" className="pr-2" />{" "}
          {t("tabs.a")}
        </div>
        <div
          className={`w-[40%] shadow-lg ${isBackgroundWhite && module!=="tickets"?"border border-gray-500":""} text-lg flex items-center px-2 ${
            module === "tickets" ? ticketBackGroundColor : "text-primary"
          }`}
          onClick={() => {
            setModule("tickets");
          }}
        >
          <img src={ticketIcon} alt="ticket" />
          {t("tabs.b", { date: showtimesDate })}
        </div>
      </div>
      <div className="h-[92%]">
        {module === "concessions" && (
          <Concession serviceArea={"Cinema"} serviceAreaNum={0} />
        )}
        {module === "tickets" && <Ticket />}
      </div>
    </>
  );
};

export default Home;
