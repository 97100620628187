import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import { addToOrder } from "../../../redux/slices";
import UpSign from "../../../assets/images/up.png";
import DownSign from "../../../assets/images/down.png";
import { numToCurrency } from "../../../utils/currencyHelper";
import {useTranslation} from "react-i18next";

const ConcessionModal = ({
  openStatus,
  closeFunc,
  data,
  disableClose = false,
  callBack,
}) => {

  const { t } = useTranslation(["OrderList", "Common"]);

  const dispatch = useDispatch();

  const [itemsState, setItemsState] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const { cinemaSettings } = useSelector((state) => state.profile);

  useEffect(() => {
    if (data && Array.isArray(data[0]) === false) {
      setItemsState(data);
    } else {
      setItemsState(data[0]);
    }
  }, [data]);

  let [counter, setCounter] = useState(0);
  const clamp = (num, min = 0, max = 10) => Math.min(Math.max(num, min), max);
  let maxPage = Math.ceil(itemsState?.length / 15);
  const start = currentPage * 15;
  const end = start + 15;
  const prevPage = () => {
    let newPage = clamp(currentPage - 1, 0, maxPage);

    setCurrentPage(newPage);
  };
  const nextPage = () => {
    let newPage = clamp(currentPage + 1, 0, maxPage);
    /*this check helps to confirm if there would be items on the next page
    if a case where there won't be items there it does not bother going to that page and just returns

    */
    let tempStart = newPage * 15;
    let tempEnd = tempStart + 15;
    if (itemsState?.slice(tempStart, tempEnd)?.length < 1) {
      return;
    }

    setCurrentPage(newPage);
  };

  const onClickHandler = (item) => {
    if (data && Array.isArray(data[0]) === false) {
      return () => {
        dispatch(
          addToOrder({
            ...item,
            id: item.itemId,
            packageId: item.itemPackageId,
            parentId: item.itemParentId,
            //this id ensures if a delete attempt is made on the child of a package the entire cart is cleared
            idForDelete: item.id,
          })
        );
        closeFunc();
        if (callBack) {
          callBack?.func();
        }
      };
    } else {
      return () => {
        dispatch(
          addToOrder({
            ...item,
            id: item.itemId,
            packageId: item.itemPackageId,
            parentId: item.itemParentId,
            //this id ensures if a delete attempt is made on the child of a package the entire cart is cleared
            idForDelete: item.id,
          })
        );
        let c = counter + 1;
        if (data.length === c) {
          closeFunc();
          setCounter(0);
          if (callBack) {
            callBack?.func();
          }
        } else {
          setCounter(c);
          setItemsState(data[c]);
        }
      };
    }
  };

  return (
    <Transition appear show={openStatus} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          // closeFunc();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-[760px]  transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  {t('concession.title')}
                </Dialog.Title>
                <div className="bg-primary  grid grid-cols-5 grid-rows-3 gap-2 p-2">
                  {itemsState?.slice(start, end)?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="rounded bg-white p-2 hover:bg-secondary hover:text-white flex items-center justify-center   "
                        onClick={onClickHandler(item)}
                      >
                        {" "}
                        <div>
                          <div className=" flex justify-center items-center">
                            {/* <div
                              className="w-[50px] h-[50px] bg-no-repeat bg-contain bg-center"
                              style={{
                                backgroundImage: `url(${item.imageUrl})`,
                              }}
                            ></div> */}
                          </div>
                          {item.imageUrl && cinemaSettings.includePOSImages && (
                            <div
                              className=" w-[50px] h-[50px] bg-no-repeat bg-center bg-contain"
                              style={{
                                backgroundImage: `url("${item.imageUrl}")`,
                              }}
                            ></div>
                          )}

                          <div
                            className={`${
                              !cinemaSettings.includePOSImages ? "py-2" : ""
                            }`}
                          >
                            <p className="text-center text-[14px] font-bold">
                              {item.name}
                            </p>
                            {item.price ? (
                              <p className="text-center text-[12px] font-bold ">{`${numToCurrency(
                                item.price
                              )}`}</p>
                            ) : (
                              ""
                            )}
                            {cinemaSettings.stockControl && item.stock ? (
                              <p className="text-center text-[12px] font-bold ">{`Stock: ${item.stock}`}</p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="mt-4 flex justify-around ">
                  <div
                    className=" border-2 w-[50px] h-[35px] rounded mb-2 bg-no-repeat bg-[50%] bg-center"
                    style={{
                      backgroundImage: `url(${UpSign})`,
                    }}
                    onClick={() => {
                      prevPage();
                    }}
                  ></div>
                  {!disableClose && (
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => {
                        closeFunc();
                      }}
                    >
                      {t('Common:button.cancel')}
                    </button>
                  )}
                  <div
                    className=" border-2 w-[50px] h-[35px] rounded mb-2 bg-no-repeat bg-[50%] bg-center "
                    style={{
                      backgroundImage: `url(${DownSign})`,
                    }}
                    onClick={() => {
                      nextPage();
                    }}
                  ></div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
    
  );
};
export default ConcessionModal;
