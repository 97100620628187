import { useEffect, useState, useRef } from "react";
import Card from "../../assets/images/card.png";
import Cash from "../../assets/images/cash.png";
import {
  clearOrder,
  getTotals,
  createOrder,
  getShowtimes,
  getItems,
  setOrderError,
  completeBooking,
  resetIds,
  setPrintLastOrder,
  completeCreatedOrder,
  setHasPrinted,
  fetchTax,
  manualUpdateItems,
  manualUpdateShowtimes,
  completeCreatedReservation,
} from "../../redux/slices";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import PaymentChannels from "./paymentChannelsModal";
import CashChangeModal from "./cashChangeModal";
import uuid from "react-uuid";
import ReceiptContainer from "./Receipt";
import { STATUS } from "../../utils/constants";
import addHours from "date-fns/addHours";
import { numToCurrency } from "../../utils/currencyHelper";
import { setLoyaltyData } from "../../redux/slices";
import { isToday } from "date-fns";
import { useTranslation } from "react-i18next";
import { downloadMcf } from "../../utils/mcfHelper";

const OrderPayment = () => {
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.orders);
  const { userData, offlineMode, cinemaSettings } = useSelector(
    (state) => state.profile
  );
  const { itemsList } = useSelector((state) => state.items);
  const { showtimesList } = useSelector((state) => state.showtimes);
  const [paymentMode, setPaymentMode] = useState([]);
  const componentRef = useRef();
  const [isCash, setIsCash] = useState(false);
  const [showChannelsModal, setShowChannelsModal] = useState(false);
  const [offlineSaleId, setOfflineSaleId] = useState("");
  const [promoCodeId, setPromoCodeId] = useState(null);
  const [discountCodeId, setDiscountCodeId] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [lumiData, setLumiData] = useState({
    phoneNumber: null,
    email: null,
    firstName: null,
    lastName: null,
    giftcardCode: null,
    giftcardCodeValue: 0,
  });
  const { i18n, t } = useTranslation(["OrderPayment"]);
  const batchedOrderCreation = cinemaSettings?.orderCreation === 1;

  const [methodsList, setMethodsList] = useState([]);
  const [cashAmount, setCashAmount] = useState("");

  const resetIsDisabled = () => {
    setTimeout(() => {
      setIsDisabled(false);
    }, 3000);
  };

  const storeLastOrder = () => {
    localStorage.setItem("lastOrder", JSON.stringify(orders.orderItems));
  };

  //function to set order id of offline sale before printing
  const setOrderIdBeforePrint = (func) => {
    if (offlineMode || batchedOrderCreation) {
      func && func();
    }
  };

  //function to reduce array for manualUpdate

  const arrayReducer = (array) => {
    return array.reduce((accumulator, currentValue) => {
      // Check if the fruit already exists in the accumulator
      if (accumulator[currentValue.id]) {
        // If it exists, add the quantity to the existing value
        accumulator[currentValue.id].quantity += currentValue.quantity;
      } else {
        // If it doesn't exist, initialize it with the current value
        accumulator[currentValue.id] = {
          id: currentValue.id,
          quantity: currentValue.quantity,
        };
      }
      return accumulator;
    }, {});
  };

  //function to manually update tickets and concessions for batched upload

  const manualUpdate = () => {
    const orderConcessions = orders?.orderItems
      ?.filter((item) => {
        return !item.showtimeId && !item.isPackage;
      })
      ?.map((x) => {
        return { id: x.id, quantity: x.orderQuantity };
      });
    const orderTickets = orders?.orderItems
      ?.filter((item) => {
        return item.showtimeId;
      })
      ?.map((x) => {
        return { id: x.showtimeId, quantity: x.orderQuantity };
      });

    // Create an object to store the accumulated quantities for each fruit
    const formattedConcession = Object.values(arrayReducer(orderConcessions));
    const formattedTickets = Object.values(arrayReducer(orderTickets));

    //Section for tickets
    if (orderTickets?.length > 0) {
      let updatedShowtimes = [];

      showtimesList?.result?.forEach((element) => {
        if (
          formattedTickets
            .map((x) => {
              return x.id;
            })
            .includes(element.id)
        ) {
          updatedShowtimes.push({
            ...element,
            seatsSold:
              element?.seatsSold +
              formattedTickets?.find((x) => x.id === element.id)?.quantity,
          });
        } else {
          updatedShowtimes.push(element);
        }
      });

      dispatch(
        manualUpdateShowtimes({ ...showtimesList, result: updatedShowtimes })
      );
    }

    //section for concession

    if (orderConcessions?.length > 0) {
      let updatedItems = [];

      itemsList?.forEach((element) => {
        //check for Single Items
        if (element?.itemGroup === "Single") {
          if (
            formattedConcession
              .map((x) => {
                return x.id;
              })
              .includes(element.id)
          ) {
            updatedItems.push({
              ...element,
              stock:
                element?.stock -
                formattedConcession?.find((x) => x.id === element.id)?.quantity,
            });
          } else {
            updatedItems.push(element);
          }
        } else {
          updatedItems.push(element);
        }
      });

      dispatch(manualUpdateItems(updatedItems));
    }
  };

  const onlineRefresh = () => {
    if (offlineMode || batchedOrderCreation) {
      manualUpdate();
    } else {
      dispatch(getShowtimes());
      dispatch(getItems());
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => {
      setOrderIdBeforePrint();
    },
    onAfterPrint: () => {
      //to allow loading spinner keep spinner after network request has resolved but Receipt has not printed
      dispatch(setHasPrinted("hasPrinted"));
      storeLastOrder();
      setPaymentMode("");
      dispatch(clearOrder());
      onlineRefresh();
      dispatch(resetIds());
      setOfflineSaleId("");
      dispatch(setPrintLastOrder(false));
      setPromoCodeId(null);
      setDiscountCodeId(null);
      dispatch(setLoyaltyData(null));
      setCashAmount("");
      setLumiData({
        phoneNumber: null,
        email: null,
        firstName: null,
        lastName: null,
        giftcardCode: null,
        giftcardCodeValue: 0,
      });
      setMethodsList([]);
    },
  });

  useEffect(() => {
    dispatch(getTotals());
  }, [orders, dispatch]);
  // console.log({offlineSaleId})

  const orderCreationHandler = () => {
    let firstTicketDate = new Date(
      orders?.orderItems?.filter((item) => {
        return item?.showtimeId;
      })[0]?.startTime
    );
  
    let itemSales = orders.orderItems
      .filter((item) => {
        return !item.showtimeId && !item.isPackage;
      })
      .map((item) => {
        return {
          cinemaId: userData.cinemaId,
          name: item.name,
          itemId: item.id,
          quantity: item.orderQuantity,
          itemParentId: item.parentId || null,
          itemPackageId: item.packageId || null,
          packageTicketId: item.packageTicketId || null,
          price: item.priceInPackage || item.price,
          isLoyaltyItem: item?.isDiscountApplied,
          amount: !item.priceInPackage
            ? +item.orderQuantity * +item.price
            : +item.orderQuantity * +item.priceInPackage,
        };
      });

    let ticketSales = orders.orderItems
      .filter((item) => {
        return item.showtimeId;
      })
      .map((item) => {
        return {
          cinemaId: userData.cinemaId,
          showtimeId: item.showtimeId,
          ticketId: item.ticketId,
          voucherCode: item.voucherCode || null,
          priceCardTicketId: item.priceCardTicketId,
          quantity: item.orderQuantity,
          ticketPrice: item.priceInPackage || item.price,
          // TODO: check if this package ticket id might be causing offline issue
          packageTicketId: item.packageTicketId || null,
          amount: !item.priceInPackage
            ? +item.orderQuantity * +item.price
            : +item.orderQuantity * +item.priceInPackage,
          seatNumber: item?.seatNumber,
          seatLayoutId: item?.seatLayoutId,
          rowName: item?.rowName,
          isLoyaltyTicket: item?.isDiscountApplied,
        };
      });

    const isBookedOrder = orders.orderItems.filter((item) => {
      return item.bookedOrder === true;
    });
    const isReservedOrder = orders.orderItems.filter((item) => {
      return item.bookedOrder === false&&item.reservedOrder===true;
    });
// console.log({isReservedOrder,orderItems:orders.orderItems,bookingDetails:orders.bookingDetails})
    if (orders.printLastOrder) {
      return () => {
        console.log("order reprint");
      };
    }
    //this if block gets triggered if the order is an offline order
    if (offlineMode || batchedOrderCreation) {
      //setting the random id variable to the generated uuid
      const randomId = uuid();
      // console.log({randomId})
      setOfflineSaleId(randomId.slice(0, 16));
      // console.log({idFunc:offlineSaleId})
      let offlineSales =
        JSON.parse(localStorage.getItem("offlineOrders")) || [];
      const newOfflineSales = [
        ...offlineSales,
        {
          cinemaId: userData.cinemaId,
          itemSales,
          ticketSales,
          sourceId: "source-d9c05d",
          paymentChannels: paymentMode,
          // Datetimereserved here was modified to cater for advance ticket sales.
          // In a case where is a ticket sale it picks the datetime reserved based on the showtime
          // of the ticket
          dateTimeReserved:
            ticketSales.length > 0
              ? isToday(firstTicketDate)
                ? new Date()
                : firstTicketDate
              : new Date(),
          offlineId: randomId,
          discountCodeId: null,
          promoCodeId: null,
          userId: userData.id,
          loyalty: orders.loyaltyData,
        },
      ];
      localStorage.setItem("offlineOrders", JSON.stringify(newOfflineSales));
      localStorage.setItem("lastOrderId", JSON.stringify(randomId));

      return;
    }
    if (isBookedOrder.length > 0) {
      return dispatch(completeBooking(orders.bookingDetails.id));
    } else if (isReservedOrder.length > 0) {
      // console.log({enterrserve:"isreserve"})
      return dispatch(completeCreatedReservation({
        bookingId: orders.bookingDetails.id,
        orders: paymentMode.map(mode => ({
          channelId: mode.channelId || null,
          otherChannelId: mode.otherChannelId||null,
          amount:mode.amount
        }))
      }))
    }else {
      return dispatch(
        createOrder({
          cinemaId: userData.cinemaId,
          itemSales,
          ticketSales,
          sourceId: "source-d9c05d",
          paymentChannels: paymentMode,
          // Datetimereserved here was modified to cater for advance ticket sales.
          // In a case where is a ticket sale it picks the datetime reserved based on the showtime
          // of the ticket
          dateTimeReserved:
            ticketSales.length > 0
              ? isToday(firstTicketDate)
                ? new Date()
                : firstTicketDate
              : new Date(),
          offlineId: null,
          discountCodeId,
          promoCodeId,
          loyalty: orders.loyaltyData,
          lumiLoyalty: lumiData,
        })
      );
    }
  };

  useEffect(() => {
    const isOnlineBooking =
      orders?.orderItems?.filter((item) => {
        return item?.bookedOrder === true;
      })?.length > 0;

    if (orders.printLastOrder && !offlineMode) {
      handlePrint();
      return;
    }

    if (offlineMode && isOnlineBooking) {
      dispatch(
        setOrderError({
          error: t("paymentchannel_modal.errors.order_mode"),
        })
      );
      return;
    }

    if ((offlineMode || batchedOrderCreation) && paymentMode.length > 0) {
      orderCreationHandler();
      return;
    }

    //check to ensure that users cannot add a new item when trying to complete a booked order
    if (
      isOnlineBooking &&
      orders?.orderItems?.find((item) => {
        return item?.bookedOrder !== true;
      })
    ) {
      dispatch(
        setOrderError({
          error: t("paymentchannel_modal.errors.order2"),
        })
      );

      return;
    }

    if (paymentMode.length > 0 && !offlineMode && !batchedOrderCreation) {
      dispatch(setHasPrinted("isProcessing"));
      orderCreationHandler()
        .unwrap()
        .then((data) => {
          // console.log({data})
          dispatch(completeCreatedOrder(data))
            .unwrap()
            .then(() => {
              dispatch(
                fetchTax({
                  cinemaId: userData.cinemaId,
                  orderId: data,
                  amount: orders?.orderTotalAmount,
                })
              )
                .unwrap()
                .then((taxData) => {
                  // if (cinemaSettings?.generateJson) {
                  //   downloadJsonFile(taxData);
                  // }
                  handlePrint();
                });
            });
        })
        .catch((err) => {
          dispatch(setHasPrinted("failed"));
          dispatch(
            setOrderError({
              error: err || t("paymentchannel_modal.errors.create_order"),
            })
          );
        });
    }

   
    //for orders that just have a discount code or promocode
    if (
      paymentMode.length === 0 &&
      !offlineMode &&
      (discountCodeId || promoCodeId)
    ) {
      orderCreationHandler()
        .unwrap()
        .then((data) => {
          dispatch(completeCreatedOrder(data))
            .unwrap()
            .then(() => {
              dispatch(
                fetchTax({
                  cinemaId: userData.cinemaId,
                  orderId: data,
                  amount: orders?.orderTotalAmount,
                })
              )
                .unwrap()
                .then((taxData) => {
                  if (cinemaSettings?.generateJson) {
                    // downloadJsonFile(taxData);
                    downloadMcf({
                      taxData,
                      order: orders,
                      userData,
                      paymentMode,
                      cashAmount,
                    });
                  }
                  handlePrint();
                });
            });
        })
        .catch((err) => {
          dispatch(
            setOrderError({
              error: err || t("paymentchannel_modal.errors.create_order"),
            })
          );
        });
    }
  }, [paymentMode, dispatch, discountCodeId, promoCodeId]);

  useEffect(() => {
    if (
      offlineSaleId &&
      paymentMode.length &&
      (offlineMode || batchedOrderCreation)
    ) {
      dispatch(
        fetchTax({
          cinemaId: userData.cinemaId,
          orderId: offlineSaleId,
          amount: orders?.orderTotalAmount,
        })
      )
        .unwrap()
        .then((data) => {
          if (cinemaSettings?.generateJson) {
            // downloadJsonFile(data);
            downloadMcf({
              taxData: data,
              order: orders,
              userData,
              paymentMode,
              cashAmount,
            });
          }
        });
      handlePrint();
    }
  }, [
    offlineSaleId,
    offlineMode,
    paymentMode.length,
    discountCodeId,
    promoCodeId,
    batchedOrderCreation,
  ]);

  const cardPaymentMode = () => {
    if (offlineMode || batchedOrderCreation) {
      setIsDisabled(true);
      resetIsDisabled();
      clearTimeout(resetIsDisabled);
    }

    if (
      orders.orderItems?.length > 0 &&
      orders.createOrderStatus !== STATUS.PENDING
    ) {
      setPaymentMode([
        {
          channelId: "channel-c2e01f",
          amount: orders.orderTotalAmount,
        },
      ]);
      setMethodsList([
        ` ${t("paymentchannel_modal.label.card")}:${numToCurrency(
          orders.orderTotalAmount
        )}`,
      ]);
    } else {
      return () => {};
    }
  };

  return (
    <>
      <div className=" h-[15%]  rounded shadow-md border-2 flex py-1">
        <button
          className=" w-[33%] h-[100%] "
          onClick={cardPaymentMode}
          disabled={offlineMode || batchedOrderCreation ? isDisabled : false}
        >
          <div className="flex justify-center mb-2">
            <img src={Card} alt="cardimg" />
          </div>

          <p className="text-center text-primary font-bold">
            {t("payment.card")}
          </p>
        </button>
        <button
          className=" w-[34%] h-[100%] border-x-2 border-primary "
          onClick={() => {
            if (orders.orderItems?.length > 0) {
              setIsCash(true);
            }
          }}
        >
          <div className="flex justify-center mb-2">
            <img src={Cash} alt="cashimg" />
          </div>
          <p className="text-center text-primary font-bold">
            {t("payment.cash")}
          </p>
        </button>

        <div className="hidden">
          <ReceiptContainer
            ref={componentRef}
            offlineSaleId={offlineSaleId}
            methodsList={methodsList}
          />
        </div>
        <div
          className="h-[100%] w-[33%]"
          onClick={() => {
            if (orders.orderItems?.length > 0) {
              setShowChannelsModal(true);
            }
          }}
        >
          <div
            className=" w-[100%] h-[65%]  bg-no-repeat bg-contain bg-center"
            style={{
              backgroundImage: `url(${process.env.REACT_APP_LOGO_URL2})`,
            }}
          ></div>
          <p className="text-center text-primary font-bold text-[12px]">
            {t("payment.multi")}
          </p>
        </div>
      </div>
      {orders.orderItems?.length > 0 && (
        <PaymentChannels
          openStatus={showChannelsModal}
          closeFunc={() => {
            setShowChannelsModal(false);
          }}
          setPaymentMode={(channels) => {
            setPaymentMode(channels);
          }}
          setDiscountCodeId={setDiscountCodeId}
          setPromoCodeId={setPromoCodeId}
          setLumiData={setLumiData}
          lumiData={lumiData}
          setMethodsList={setMethodsList}
        />
      )}
      {orders.orderItems?.length > 0 && (
        <CashChangeModal
          openStatus={isCash}
          closeFunc={() => {
            setIsCash(false);
          }}
          setPaymentMode={(channels) => {
            setPaymentMode(channels);
          }}
          setMethodsList={setMethodsList}
          cashAmount={cashAmount}
          setCashAmount={setCashAmount}
        />
      )}
    </>
  );
};
export default OrderPayment;
