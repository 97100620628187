import axios from "axios";
import { redirectFunc } from "../../utils/redirect";

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { STATUS, tokenKey } from "../../utils/constants";

export const checkVoucherCode = createAsyncThunk(
  "check_voucher_code",
  async (
    { voucherCode, ticketId, cinemaId, filmId },
    { fulfillWithValue, rejectWithValue }
  ) => {
    const token = localStorage.getItem(tokenKey);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_V2}Vouchers/CheckCode?voucherCode=${voucherCode}&ticketId=${ticketId}&cinemaId=${cinemaId}&filmId=${filmId}`,
        { headers: { Authorization: "Bearer " + token } }
      );

      const voucherCodeData = response.data.data;

      return fulfillWithValue(voucherCodeData);
    } catch (err) {
      redirectFunc({ ...err });

      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

const initialState = {
  error: "",
  status: STATUS.IDLE,
  voucherData: {},
};

export const voucherSlice = createSlice({
  name: "vouchers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(checkVoucherCode.pending, (state) => {
        state.error = "";
        state.status = STATUS.PENDING;
      })
      .addCase(checkVoucherCode.fulfilled, (state, action) => {
        state.error = "";
        state.status = STATUS.RESOLVED;
        state.voucherData = action.payload;
      })
      .addCase(checkVoucherCode.rejected, (state, action) => {
        state.error = action.payload;
        state.status = STATUS.REJECTED;
      });
  },
});

export const voucherReducer = voucherSlice.reducer;
