import CryptoJS from "crypto-js";
const secretKey = process.env.REACT_APP_SALT;
export const changeArrShape = (prop, arr, shape = {}) => {
  const newShape = arr?.reduce((acc, curr) => {
    shape[curr[prop]] = curr;
    return acc;
  }, shape);
  return newShape || [];
};

export const encode = (value) => {
  if (!secretKey) {
    throw new Error("Missing SALT");
  }
  const ciphertext = CryptoJS.AES.encrypt(value, secretKey).toString();
  return encodeURIComponent(ciphertext);
};

export const decode = (value) => {
  if (!secretKey) {
    throw new Error("Missing 'SALT'");
  }

  if (!value) {
    throw new Error("Missing value");
  }
  const bytes = CryptoJS.AES.decrypt(decodeURIComponent(value), secretKey);
  const originalId = bytes.toString(CryptoJS.enc.Utf8);
  return originalId;
};

const formatPackageData = (ticketByQuantity, isReservation) => {
  const packageOrder = [];
  for (const packagedTicketId in ticketByQuantity) {
    for (const orderQty in packagedTicketId) {
      ticketByQuantity[packagedTicketId][orderQty]?.forEach((item) => {
        if (item.showtimeId) {
          const updateWithPackageName = {
            id: item.id,
            orderQuantity: item.quantity,
            price: item.quantity * item.ticketPrice,
            name: item.ticketName,
            bookedOrder: isReservation,
            
            showtimeId: item.showtimeId || false,
            screen: item.screen || null,
            ticketName: item.ticketName || "",
            seatIdentifier:
              item?.rowName || item?.seatNumber
                ? item.rowName + item.seatNumber
                : null,
            showtimeDate: new Date(item.startTime).toDateString(),
            time:
              ` ${new Date(item.startTime).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })} - ${new Date(item.endTime).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })} ` || null,
          };
          packageOrder.push(updateWithPackageName);
          packageOrder.push({
            id: item.id,
            orderQuantity: item.quantity,
            price: 0,
            name: item.film,
            bookedOrder: isReservation,
            showtimeId: item.showtimeId || false,
            screen: item.screen || null,
            ticketName: item.ticketName || "",
            seatIdentifier:
              item?.rowName || item?.seatNumber
                ? item.rowName + item.seatNumber
                : null,
            showtimeDate: new Date(item.startTime).toDateString(),
            time:
              ` ${new Date(item.startTime).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })} - ${new Date(item.endTime).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })} ` || null,
          });
        } else {
          
          packageOrder.push({
            id: item.id,
            orderQuantity: item.quantity,
            price: 0,
            name: item.item,
            hideItem: item.hideItem,
            bookedOrder: isReservation,
            showtimeId: item.showtimeId || false,
            screen: item.screen || null,
            ticketName: item.ticketName || "",
            seatIdentifier:
              item?.rowName || item?.seatNumber
                ? item.rowName + item.seatNumber
                : null,
            showtimeDate: new Date(item.startTime).toDateString(),
            time:
              ` ${new Date(item.startTime).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })} - ${new Date(item.endTime).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })} ` || null,
          });
        }
      });
    }
  }

  return packageOrder;
};

const groupTicketByQuantity = (data) => {
  const result = {};

  for (const id in data) {
    result[id] = data[id].reduce((acc, item) => {
      const { quantity } = item;
      if (!acc[quantity]) {
        acc[quantity] = [];
      }
      acc[quantity].push(item);
      return acc;
    }, {});
  }

  return result;
};

export const handlePackageTicketData = (ticketArray, isReservation) => {
  const groupedData = ticketArray.reduce((acc, curr) => {
    const key = curr.packageTicketId || curr.ticketPackageId;

    if (!acc[key]) {
      acc[key] = [];
    }

    acc[key].push(curr);
    return acc;
  }, {});

  const ticketByQuantity = groupTicketByQuantity(groupedData);

  const formattedPackagedTicketData = formatPackageData(
    ticketByQuantity,
    isReservation
  );
  return formattedPackagedTicketData;
};

export const handleNotPackageTicketData = (ticketArray, isReservation) => {
  return ticketArray.map((x) => ({
    id: x.id,
    orderQuantity: x.quantity,
    price: !isReservation ? 0 : x.price || x.ticketPrice,
    hideItem: x.hideItem,
    name: x.item || x.film,
    bookedOrder: isReservation,
    // totalTicket
    reservedOrder: isReservation,
    showtimeId: x.showtimeId || false,
    screen: x.screen || null,
    ticketName: x.ticketName || "",
    seatIdentifier:
      x?.rowName || x?.seatNumber ? x.rowName + x.seatNumber : null,
    showtimeDate: new Date(x.startTime).toDateString(),
    time:
      ` ${new Date(x.startTime).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      })} - ${new Date(x.endTime).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      })} ` || null,
  }));
};
