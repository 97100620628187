import { useEffect } from "react";
import { getUser } from "../redux/slices";
import Footer from "../components/footer";
import OrderModule from "../components/orderModule";
import PosModule from "../components/posModule";
import { PrivateRoute } from "../components/PrivateRoute";
import { useDispatch } from "react-redux";
import KeyboardModal from "../utils/KeyboardModal";
import { useLoggedIn } from "../hooks";

const PosHome = () => {
  const dispatch = useDispatch();

  
  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);
  return (
    <PrivateRoute>
      <div className="flex h-4/5 " style={{ height: "94vh" }}>
        <PosModule />
        <OrderModule />
      </div>
      <Footer />
      <KeyboardModal />
    </PrivateRoute>
  );
};
export default PosHome;
