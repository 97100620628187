import * as yup from "yup";

const loginSchema = yup
  .object()
  .shape({
    userNumber: yup.string().required("Usernumber is required"),
    pin: yup.string().required("Pin is required"),
  })
  .required();

const changePwdSchema = yup.object().shape({
  // userId: yup.string().required(""),
  oldPin: yup.string().required(""),
  newPin: yup.string().required(""),
  confirmPin: yup
    .string()
    .oneOf([yup.ref("newPin")], "Pins must match")
    .required("Pin is required"),
});

const resetPinSchema = yup.object().shape({
  authToken: yup.string().required(),
  newPin: yup.string().required("Pin is required"),
  confirmPin: yup
    .string()
    .oneOf([yup.ref("newPin")], "Pins must match")
    .required("Pin is required"),
});
const resetPinInit = {
  authToken: "",
  newPin: "",
  confirmPin: "",
};
const userSalesSchema = yup
  .object()
  .shape({
    userId: yup.string().required("Kindly select a user"),
    todayDate: yup.string().required("Kindly select a date"),
  })
  .required();

const voucherSchema = yup
  .object()
  .shape({
    voucherCode: yup.string().required("Voucher code is required"),
  })
  .required();

const giftcardSchema = yup
  .object()
  .shape({
    giftcardCode: yup.string().required("Giftcard code is required"),
    amount: yup.string().required("Amount is required"),
  })
  .required();

const loyaltyDetailsSchema = yup
  .object()
  .shape({
    phoneNumber: yup.string(),
    email: yup.string(),
    firstName: yup.string(),
    lastName: yup.string(),
    giftcardCode: yup.string(),
  })
  .required();
const reservationSchema = yup
  .object()
  .shape({
    phoneNumber: yup.string(),
    email: yup.string(),
    firstName: yup.string(),
    lastName: yup.string(),
  })
  .required();

const partialRefundSchema = yup
  .object()
  .shape({
    reason: yup.string(),
    // supplimentaryOrderId: yup.string(),
    refundItems: yup.array().of(
      yup.object().shape({
        itemSaleId: yup.string(),
        quantity: yup.number(),
        name: yup.string(),
        price: yup.number(),
        max: yup.number(),
      })
    ),
    refundTickets: yup.array().of(
      yup.object().shape({
        ticketSaleId: yup.string(),
        quantity: yup.number(),
        name: yup.string(),
        price: yup.number(),
        max: yup.number(),
      })
    ),
    amountInSession: yup.number(),
    refundPaymentChannels: yup.array().of(
      yup.object().shape({
        channelId: yup.string(),
        amount: yup.number(),
        channel: yup.string(),
        maxAmount: yup.number(),
      })
    ),
  })
  .required();

const swapTicketSchema = yup
  .object()
  .shape({
    reason: yup.string(),
    ticketSaleId: yup.string(),
    quantity: yup.number(),
    maxQuantity: yup.number(),
    amountInSession: yup.number(),
    refundPaymentChannels: yup.array().of(
      yup.object().shape({
        channelId: yup.string(),
        amount: yup.number(),
        channel: yup.string(),
        maxAmount: yup.number(),
      })
    ),
  })
  .required();

const customChannelsSchema = yup.object().shape({
  channels: yup.array().of(
    yup.object().shape({
      channelId: yup.string(),
      amount: yup.number(),
      name: yup.string(),
    })
  ),
});

export {
  loginSchema,
  userSalesSchema,
  voucherSchema,
  giftcardSchema,
  loyaltyDetailsSchema,
  partialRefundSchema,
  customChannelsSchema,
  resetPinInit,
  resetPinSchema,
  changePwdSchema,
  swapTicketSchema,
  reservationSchema,
};
