import Modal from "../../../UI/Modal";
import { useTranslation } from "react-i18next";

const ViewsModal = ({
  openStatus,
  closeFunc,
  selectedView,
  setSelectedView,
  isBackgroundWhite,
}) => {
  const { t } = useTranslation(["Home", "Common"]);
  const isListByShow =
    isBackgroundWhite && selectedView === "listByShows"
      ? "bg-white border-2 text-black border-primary"
      : isBackgroundWhite && selectedView !== "listByShows"
      ? "text-black border-white"
      : !isBackgroundWhite && selectedView === "listByShows"
      ? "bg-white"
      : "text-white";
  const isListByFilms =
    isBackgroundWhite && selectedView === "listByFilms"
      ? "bg-white border-2 text-black border-primary"
      : isBackgroundWhite && selectedView !== "listByFilms"
      ? "text-black border-white"
      : !isBackgroundWhite && selectedView === "listByFilms"
      ? "bg-white"
          : "text-white";
  const closeBtn = isBackgroundWhite? "bg-gray-500 hover:bg-gray-700 text-white":"bg-blue-300"
  return (
    <Modal
      openStatus={openStatus}
      panelStyles={`${
        isBackgroundWhite ? "bg-gray-300 border-2 border-primary" : "bg-primary"
      } w-[35vw] h-[250px]`}
    >
      <p
        className={`text-center font-bold text-[20px] p-2 ${
          isBackgroundWhite ? "text-black" : "text-white"
        }`}
      >
        {t("ticket.views.title")}
      </p>
      <div className="flex justify-around mt-2">
        <button
          className={`border-2 rounded p-1 ${isListByShow}  `}
          onClick={() => {
            setSelectedView("listByShows");
            closeFunc();
          }}
        >
          {" "}
          {t("ticket.views.tabs.a")}
        </button>
        <button
          className={`border-2 rounded p-1 ${isListByFilms}  `}
          onClick={() => {
            setSelectedView("listByFilms");
            closeFunc();
          }}
        >
          {t("ticket.views.tabs.b")}
        </button>
        {/* <button
          className={`border-2 rounded p-1 ${
            selectedView === "listByScreens" ? "bg-white" : " text-white"
          }  `}
          onClick={() => {
            setSelectedView("listByScreens");
            closeFunc();
          }}
        >
          List by Screens
        </button> */}
      </div>
      <div className="flex justify-center mt-1">
        {" "}
        <button
          onClick={closeFunc}
          className={`px-4 py-1 ${closeBtn} rounded mt-7`}
        >
          {t("Common:button.close")}
        </button>
      </div>
    </Modal>
  );
};

export { ViewsModal };
