import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getFilmDetails,
  addToOrder,
  setOfflineRestriction,
} from "../../../../redux/slices";
import { numToCurrency } from "../../../../utils/currencyHelper";
import { useTranslation } from "react-i18next";

const ListByShows = ({
  NewTicketData,
  setShowFilmDetails,
  setTicketDataForAllocation,
  addTicketToOrder,
  start,
  end,
}) => {
  const { t } = useTranslation(["Home"]);
  const dispatch = useDispatch();
  const { cinemaSettings, offlineMode } = useSelector((state) => state.profile);
  const isPosImage = cinemaSettings?.includePOSImages;
  const isBackgroundWhite = cinemaSettings?.posBackgroundColor
  const cardHover = isBackgroundWhite?"hover:bg-gray-500 hover:border-none hover:text-white":"hover:bg-primary hover:text-white"
  const [paginatedTickets, setPaginatedTickets] = useState([]);
  // console.log({NewTicketData,paginatedTickets})

  useEffect(() => {
    if (NewTicketData?.length > 0) {
      setPaginatedTickets(
        NewTicketData.slice(start, end).map((x) => {
          return {
            tickets: x?.priceCard?.tickets?.slice(0, 6),
            isToggled: false,
          };
        })
      );
    }
  }, [NewTicketData, start, end]);

  //This function helps to toggle the array of tickets in cases where there are more than 7 tickets
  const toggleFunc = (index) => {
    let newTicketList = [];

    //first check if the first half or second half is to be returned
    if (paginatedTickets[index].isToggled) {
      newTicketList = NewTicketData.slice(start, end)[
        index
      ].priceCard.tickets.slice(0, 6);
    } else {
      newTicketList = NewTicketData.slice(start, end)[
        index
      ].priceCard.tickets.slice(6, 14);
    }

    // format paginated tickets and substitute with newticketlist where applicable

    const newPaginatedData = paginatedTickets.map((x, i) => {
      if (i !== index) {
        return x;
      } else {
        return { tickets: newTicketList, isToggled: !x?.isToggled };
      }
    });

    setPaginatedTickets(newPaginatedData);
  };

  return (
    // <></>
    <div className=" h-[100%]  p-1 grid grid-rows-5 w-[93%] ">
      {NewTicketData.slice(start, end)?.map((item, index) => {
        return (
          <div className=" w-[100%] py-[3px] flex" key={item.id}>
            <div
              key={index}
              className={`rounded ${isBackgroundWhite?"shadow-lg":""}  p-2 bg-white ${
                isPosImage ? "flex" : ""
              } w-[25%] h-[100%]`}
              onClick={() => {
                setShowFilmDetails(true);
                dispatch(getFilmDetails(item?.filmId));
              }}
            >
              {isPosImage && (
                <div className="w-[35%]">
                  <div
                    className="w-full h-full bg-no-repeat  bg-cover bg-center "
                    style={{
                      backgroundImage: `url(${item.posterUrl}) `,
                    }}
                  ></div>
                </div>
              )}
              <div
                className={`flex flex-col justify-between h-full ${
                  isPosImage ? "w-[65%]" : "w-[100%]"
                } pl-1`}
              >
                {/* Top Section */}
                <div className="flex w-full items-center">
                  <p className="w-[80%] text-[16px] pr-1 truncate">
                    {item.film}
                  </p>
                  <p className={`w-[20%] h-[20px] text-[10px] ${isBackgroundWhite?"bg-gray-500":"bg-primary"} p-1 flex justify-center items-center text-white rounded`}>
                    xd
                  </p>
                </div>

                {/* Middle Section */}
                <p className="text-primary pt-1 text-[14px]">{`${new Date(
                  item.startTime
                ).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })} - ${new Date(item.endTime).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}`}</p>

                {/* Bottom Section */}
                <p className="text-[14px] flex justify-between items-center">
                  <span>
                    {`${item.totalSeats}/${item.seatsSold}/${item.totalSeats -
                      item.seatsSold} `}
                    <span className="ml-1">{`${(
                      (item.seatsSold / item.totalSeats) *
                      100
                    ).toFixed(0)}%`}</span>
                  </span>
                  <span className="shadow px-1">
                    {item.screen || t("ticket.empty.screen")}
                  </span>
                </p>
              </div>
            </div>
            <div className={`w-[75%] gap-x-2 p-1 grid grid-cols-7 grid-rows-1 border ${isBackgroundWhite?"border-gray-500":""}`}>
              {item.seatsSold < item.totalSeats &&
                paginatedTickets[index]?.tickets?.map((pricecard) => {
                  return (
                    <div
                      key={pricecard.id}
                      className={`bg-white shadow-lg w-full h-auto rounded ${cardHover} ${isBackgroundWhite?"border-2 border-app-secondary2":"border-none"} flex justify-center items-center`}
                      onClick={() => {
                        if (
                          cinemaSettings?.seatAllocation &&
                          pricecard?.seatClassId
                        ) {
                          // if (!offlineMode) {
                          setTicketDataForAllocation({
                            pricecard,
                            showtime: item,
                          });
                          return;
                          // }
                          // else {
                          //   dispatch(setOfflineRestriction(true));
                          // }
                        } else {
                          // pricecard == ticket in a pricecard; item  == ticket in a list of tickets
                          addTicketToOrder({ pricecard, item });
                        }
                      }}
                    >
                      <div className="">
                        <p className="text-[13px] text-center">
                          {pricecard.shortName}
                        </p>
                        <p className="text-[13px] text-center">
                          {numToCurrency(pricecard.price)}
                        </p>
                      </div>
                    </div>
                  );
                })}
              {item.seatsSold >= item.totalSeats && (
                <p className="text-white text-center text-[40px] col-span-7 my-auto">
                  {" "}
                  {t("ticket.finished")}
                </p>
              )}
              {item.seatsSold < item.totalSeats &&
                NewTicketData.slice(start, end)?.[index]?.priceCard.tickets
                  ?.length > 7 && (
                  <div
                    className={`${isBackgroundWhite?"border border-gray-500  bg-gray-500 text-white":"border border[bg-secondary] text-white"} h-auto order-2  rounded m-2  flex justify-center items-center`}
                    onClick={() => {
                      toggleFunc(index);
                    }}
                  >
                    <div>
                      {" "}
                      <p className="text-center">{t("ticket.more")}</p>
                      {!paginatedTickets[index]?.isToggled &&
                        NewTicketData.slice(start, end)?.[index]?.priceCard
                          .tickets?.length !== 7 && (
                          <p className="text-[10px] text-center">
                            {" "}
                            {`( 7 - ${
                              NewTicketData.slice(start, end)?.[index]
                                ?.priceCard.tickets?.length
                            }  of ${
                              NewTicketData.slice(start, end)?.[index]
                                ?.priceCard.tickets?.length
                            } )`}
                          </p>
                        )}
                      {paginatedTickets[index]?.isToggled && (
                        <p className="text-[10px] text-center">
                          {" "}
                          {`( 1 - 6  of ${
                            NewTicketData.slice(start, end)?.[index]?.priceCard
                              .tickets?.length
                          } )`}
                        </p>
                      )}
                      {!paginatedTickets[index]?.isToggled &&
                        NewTicketData.slice(start, end)?.[index]?.priceCard
                          .tickets?.length === 8 && (
                          <p className="text-[10px] text-center">
                            {" "}
                            {`( 7 of ${
                              NewTicketData.slice(start, end)?.[index]
                                ?.priceCard.tickets?.length
                            } )`}
                          </p>
                        )}
                    </div>
                  </div>
                )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export { ListByShows };
