import { Dialog, Transition } from "@headlessui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { reservationSchema } from "../../utils/schemas";
import { useDispatch, useSelector } from "react-redux";
import {
  clearOrder,
  createReservation,
  setKeyboardConfiguration,
  setShowKeyboard,
} from "../../redux/slices";
import { useKeyboard } from "../../context/KeyboardContext";
import { isToday } from "date-fns";
import { Spinner } from "../UI/Spinner";
import { STATUS } from "../../utils/constants";

const ReserveCompleteModal = ({ openStatus, closeFunc }) => {
  const { t } = useTranslation(["Common", "OrderPayment"]);
  const dispatch = useDispatch();
  const {
    orderItems,
    createReservationStatus,
    createReservationError,
  } = useSelector((state) => state.orders);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const { setSetSharedInput } = useKeyboard();
  const [reservationBookingId, setReservationBookingId] = useState("");
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(reservationSchema),
    defaultValues: {
      phoneNumber: "",
      email: "",
      firstName: "",
      lastName: "",
    },
    mode: "onChange",
  });

  const ticketSales = useMemo(() => {
    return orderItems
      .filter((item) => item.showtimeId)
      .map((ticket) => ({
        showtimeId: ticket.showtimeId,
        priceCardTicketId: ticket.priceCardTicketId,
        packageTicketId: ticket.packageTicketId || null,
        ticketId: ticket.ticketId,
        ticketPrice: ticket.priceInPackage || ticket.price,
        quantity: ticket.orderQuantity,
        amount: !ticket.priceInPackage
          ? +ticket.orderQuantity * +ticket.price
          : +ticket.orderQuantity * +ticket.priceInPackage,
      }));
  }, [orderItems]);
  const itemSales = useMemo(() => {
    return orderItems
      .filter((item) => !item.showtimeId && !item.isPackage)
      .map((item) => ({
        itemId: item.id,
        itemParentId: item.parentId || null,
        itemPackageId: item.packageId || null,
        packageTicketId: item.packageTicketId || null,
        quantity: item.orderQuantity,
        price: item.priceInPackage || item.price,
        amount: !item.priceInPackage
          ? +item.orderQuantity * +item.price
          : +item.orderQuantity * +item.priceInPackage,
        name: item.name,
        foodEaseItemId: null,
        isLoyaltyItem: item?.isDiscountApplied,
      }));
  }, [orderItems]);

  const resetFunc = () => {
    setValue("phoneNumber", "");
    setValue("email", "");
    setValue("firstName", "");
    setValue("lastName", "");
    setPhoneNumber("");
    setEmail("");
    setFirstName("");
    setLastName("");
    closeFunc();
  };

  useEffect(() => {
    setValue("phoneNumber", phoneNumber);
    setValue("email", email);
    setValue("firstName", firstName);
    setValue("lastName", lastName);
  }, [phoneNumber, email, firstName, lastName]);
  // console.log({ orderItems, createReservationStatus });
  const onSubmit = (values) => {
    let firstTicketDate = new Date(
      orderItems?.filter((item) => {
        return item?.showtimeId;
      })[0]?.startTime
    );
    const body = {
      name:
        values?.firstName && values?.lastName
          ? `${values.firstName} ${values.lastName}`
          : values?.firstName
          ? values.firstName
          : values?.lastName
          ? values.lastName
          : null,
      email: values.email || null,
      phoneNumber: values.phoneNumber || null,
      isReservation: true,
      order: {
        ticketSales: [...ticketSales],
        itemSales: [...itemSales],
        sourceId: "source-d9c05d",
        dateTimeReserved:
          ticketSales.length > 0
            ? isToday(firstTicketDate)
              ? new Date()
              : firstTicketDate
            : new Date(),
      },
    };
    dispatch(createReservation(body))
      .unwrap()
      .then((data) => {
        setReservationBookingId(data.id);
        localStorage.setItem("reservationBookingId", JSON.stringify(data.id));
      });
  };
  // console.log({ reservationBookingId });
  return (
    <Transition appear show={openStatus} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          // setShowError(false);
          // setEnteredPin("");
          // closeFunc();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-bold text-center leading-6 text-gray-900"
                >
                  ORDER RESERVATION
                  <div>

                  <small className="text-center text-red-500">{ createReservationError}</small>
                  </div>
                </Dialog.Title>

                {createReservationStatus === STATUS.RESOLVED &&
                reservationBookingId ? (
                  <div className="flex flex-col space-y-8 justify-center items-center">
                    <h2 className="text-green-500">Reservation Booked Successfully</h2>
                    <p className="font-bold">
                      <span className="font-normal mr-2">Booking Id:</span>
                      {reservationBookingId}
                    </p>
                    <button
                      type="button"
                      onClick={() => {
                        resetFunc();
                        dispatch(clearOrder());
                      }}
                      className=" inline-flex justify-center rounded-md border border-transparent bg-red-700 px-4 py-2 text-sm font-medium text-white mr-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    >
                      {t("Common:button.cancel")}
                    </button>
                  </div>
                ) : (
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid grid-cols-2 gap-x-5">
                      <div className="mt-2 deleteModal flex justify-center">
                        <div>
                          <label className="text-center block">
                            {t("Common:label.name.first")}
                          </label>

                          <input
                            type="text"
                            value={firstName}
                            placeholder={t(
                              "Common:keyboard.placeholder.customer.firstName"
                            )}
                            className=" w-[100%]  h-[50px] border border-secondary rounded py-2 px-3"
                            onClick={() => {
                              dispatch(setShowKeyboard(true));
                              dispatch(
                                setKeyboardConfiguration({
                                  type: "alphaNumeric",
                                  sharedInput: firstName,
                                  placeHolder: t(
                                    "Common:keyboard.placeholder.customer.firstName"
                                  ),
                                  // setSharedInput: (x) => {
                                  //   setFirstName(x);
                                  // },
                                })
                              );
                              setSetSharedInput(() => setFirstName);
                            }}
                          />
                          {errors?.firstName?.message && (
                            <p className="text-red-500 text-center">
                              {errors?.firstName?.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="mt-2 deleteModal flex justify-center">
                        <div>
                          <label className="text-center block">
                            {t("Common:label.name.last")}
                          </label>

                          <input
                            type="text"
                            value={lastName}
                            placeholder={t(
                              "Common:keyboard.placeholder.customer.lastName"
                            )}
                            className=" w-[100%]  h-[50px] border border-secondary rounded py-2 px-3"
                            onClick={() => {
                              dispatch(setShowKeyboard(true));
                              dispatch(
                                setKeyboardConfiguration({
                                  type: "alphaNumeric",
                                  sharedInput: lastName,
                                  placeHolder: t(
                                    "Common:keyboard.placeholder.customer.lastName"
                                  ),
                                  // setSharedInput: (x) => {
                                  //   setLastName(x);
                                  // },
                                })
                              );
                              setSetSharedInput(() => setLastName);
                            }}
                          />
                          {errors?.lastName?.message && (
                            <p className="text-red-500 text-center">
                              {errors?.lastName?.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="mt-2 deleteModal flex justify-center">
                        <div>
                          <label className="text-center block">
                            {t("OrderPayment:paymentchannel_modal.label.email")}
                          </label>

                          <input
                            type="email"
                            value={email}
                            placeholder={t(
                              "Common:keyboard.placeholder.customer.email"
                            )}
                            className=" w-[100%]  h-[50px] border border-secondary rounded py-2 px-3"
                            onClick={() => {
                              dispatch(setShowKeyboard(true));
                              dispatch(
                                setKeyboardConfiguration({
                                  type: "alphaNumeric",
                                  sharedInput: email,
                                  placeHolder: t(
                                    "Common:keyboard.placeholder.customer.email"
                                  ),
                                  // setSharedInput: (x) => {
                                  //   setEmail(x);
                                  // },
                                })
                              );
                              setSetSharedInput(() => setEmail);
                            }}
                          />
                          {errors?.email?.message && (
                            <p className="text-red-500 text-center">
                              {errors?.email?.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="mt-2 deleteModal flex justify-center ">
                        <div>
                          <label className="text-center block ">
                            {t("OrderPayment:paymentchannel_modal.label.phone")}
                          </label>

                          <input
                            type="number"
                            value={phoneNumber}
                            placeholder={t(
                              "Common:keyboard.placeholder.customer.phone"
                            )}
                            className=" w-[100%]  h-[50px] border border-secondary rounded py-2 px-3"
                            onClick={() => {
                              dispatch(setShowKeyboard(true));
                              dispatch(
                                setKeyboardConfiguration({
                                  type: "numeric",
                                  sharedInput: phoneNumber,
                                  placeHolder: t(
                                    "Common:keyboard.placeholder.customer.phone"
                                  ),
                                  // setSharedInput: (x) => {
                                  //   setPhoneNumber(x);
                                  // },
                                })
                              );
                              setSetSharedInput(() => setPhoneNumber);
                            }}
                          />
                          {errors?.phoneNumber?.message && (
                            <p className="text-red-500 text-center">
                              {t(
                                "OrderPayment:paymentchannel_modal.errors.required.phone"
                              )}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 flex justify-center">
                      <button
                        type="button"
                        onClick={() => {
                          resetFunc();
                        }}
                        className=" inline-flex justify-center rounded-md border border-transparent bg-red-700 px-4 py-2 text-sm font-medium text-white mr-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      >
                        {t("Common:button.cancel")}
                      </button>
                      <button
                        type="submit"
                        className=" ml-2 inline-flex items-center justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      >
                        <span>{t("Common:button.submit")}</span>
                        <Spinner
                          className="!w-3 !h-3 mx-1 text-white fill-blue-800"
                          status={createReservationStatus}
                        />
                      </button>
                    </div>
                  </form>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ReserveCompleteModal;
