import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createOfflineOrder, getInScreenOrdersCount } from "../../redux/slices";
import { STATUS } from "../../utils/constants";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation(["Footer"]);
  const { userData, offlineMode, isOnline, cinemaSettings } = useSelector(
    (state) => state.profile
  );
  const dispatch = useDispatch();
  const { loyaltyData, createOfflineOrderStatus } = useSelector(
    (state) => state.orders
  );
  const currentDate = new Date().toDateString();
  const [time, setTime] = useState();
  const [tracker, setTracker] = useState(0);
  const batchedOrderCreation = cinemaSettings?.orderCreation === 1;
  const isBackgroundWhite = cinemaSettings?.posBackgroundColor
  const backgroundColor = isBackgroundWhite?"bg-gray-300 text-black":"bg-app-secondary2 text-white"
  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date().toLocaleTimeString());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  //in screen check timer
  useEffect(() => {
    if (cinemaSettings?.inScreenBookingsEnabled) {
      const timer = setInterval(() => {
        dispatch(getInScreenOrdersCount());
      }, 60000);

      return () => {
        clearInterval(timer);
      };
    }
  }, []);

  //batched offline upload

  let offlineSales = JSON.parse(localStorage.getItem("offlineOrders")) || [];

  const createFunction = () => {
    //foreach helps delete duplicates that might have happened due to double clicking
    let filteredOfflineSales = [];
    offlineSales.forEach((element) => {
      if (
        !filteredOfflineSales
          .map((x) => {
            return x.offlineId;
          })
          .includes(element.offlineId)
      ) {
        filteredOfflineSales.push(element);
      }
    });

    dispatch(createOfflineOrder(filteredOfflineSales))
      .unwrap()
      .then((data) => {
        const uploadedOfflineSale = data?.orderMessages
          ?.filter((order) => {
            return (
              order?.status === "Success" ||
              order?.messages.includes("Order Already Uploaded")
            );
          })
          .map((x) => {
            return x.offlineId;
          });

        localStorage.setItem(
          "offlineOrders",
          JSON.stringify(
            offlineSales?.filter((sale) => {
              return !uploadedOfflineSale.includes(sale.offlineId);
            })
          )
        );
      })
      .catch(() => {});
  };

  useEffect(() => {
    const orderCheckTimer = setInterval(() => {
      if (
        offlineSales?.length > 0 &&
        createOfflineOrderStatus !== STATUS.PENDING &&
        isOnline &&
        (batchedOrderCreation || cinemaSettings?.automaticOfflineUpload)
      ) {
        createFunction();
      }
      setTracker((prev) => prev + 1);
    }, 5000);

    return () => {
      clearInterval(orderCheckTimer);
    };
  }, [createOfflineOrderStatus, isOnline, tracker]);

  return (
    <div
      className={`flex justify-around h-1/5 ${backgroundColor} px-1 py-2 `}
      style={{ height: "6vh" }}
    >
      <p>{currentDate}</p>
      <p>{time}</p>
      <p>{`${userData.firstName}  ${userData.lastName}`}</p>
      <p>
        {t("mode.title")}:{" "}
        {offlineMode ? (
          <span className="text-black font-bold">{t("mode.off")}</span>
        ) : (
          t("mode.on")
        )}
      </p>
      {loyaltyData?.phoneNumber && (
        <p className="animate-pulse text-black font-bold">
          {t("loyalty.title")}: {t("loyalty.on")}
        </p>
      )}
    </div>
  );
};
export default Footer;
