import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getBooking,
  setKeyboardConfiguration,
  setShowKeyboard
} from "../../../redux/slices";
import NumPad from "react-numpad";
import { Spinner } from "../../UI/Spinner";
import { useTranslation } from "react-i18next";
import { useKeyboard } from "../../../context/KeyboardContext";

const FindBooking = ({ func }) => {
  const { t } = useTranslation(["Booking", "Common"]);
  const dispatch = useDispatch();
  const [bookingCode, setBookingCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { getBookingError, getBookingStatus } = useSelector(
    state => state.orders
  );
  const {cinemaSettings} = useSelector(state => state.profile)
  const { setSetSharedInput } = useKeyboard()
  const bookingCodeChangeHandler = value => {
    setBookingCode(value);
  };
  const isBackgroundWhite = cinemaSettings?.posBackgroundColor
  const submitButton = isBackgroundWhite?"bg-gray-600 text-white hover:bg-gray-800":"bg-secondary text-white"
  const headerText=isBackgroundWhite?"text-gray-800":"text-[#fee27d]"
  const backgroundColor = isBackgroundWhite ? "bg-gray-400" : "bg-primary"
  const labelStyle = isBackgroundWhite?"text-gray-800":"text-white"
  const inputStyle = isBackgroundWhite?"shadow-lg border border-primary":"border border-secondary"
  // console.log(getBookingError);
  const submitHandler = e => {
    e.preventDefault();

    if (!bookingCode && !phoneNumber && !emailAddress) {
      setErrorMessage(t("errors.required"));
      return;
    }

    if ((emailAddress && !phoneNumber) || (!emailAddress && phoneNumber)) {
      setErrorMessage(t("errors.details"));
      return;
    }

    dispatch(
      getBooking({ bookingId: bookingCode, email: emailAddress, phoneNumber })
    )
      .unwrap()
      .then(data => {
     
        func();
      })
      .catch(err => {
        console.log(err);
      });
  };
  return (
    <div className={`${backgroundColor} h-[100%] px-[100px] py-5`}>
      <h2 className={`${headerText} text-[30px] text-center`}>
        {t("title.find")}
      </h2>
      <p className="text-center text-red-400">{getBookingError}</p>
      {errorMessage && (
        <p className="text-center text-red-400">{errorMessage}</p>
      )}
      <form onSubmit={submitHandler} className="findBooking">
        <p className={`${labelStyle} text-[18px] pb-[10px] `}>{t("label.code")}</p>
        <NumPad.Number
          placeholder={t("placeholder.code")}
          type="number"
          value={bookingCode}
          name={"bookingCode"}
          onChange={bookingCodeChangeHandler}
        />
        <p className={`${labelStyle} text-[18px] pb-[10px] `}>{t("label.phone")}</p>
        <input
          type="text"
          value={phoneNumber}
          placeholder={t("placeholder.phone")}
          className={` w-[100%] ${inputStyle} rounded py-2 px-3 my-3`}
          onClick={() => {
            dispatch(setShowKeyboard(true));
            dispatch(
              setKeyboardConfiguration({
                type: "numeric",
                sharedInput: phoneNumber,
                placeHolder: t("Common:keyboard.placeholder.customer.phone"),
                // setSharedInput: x => {
                //   setPhoneNumber(x);
                // }
              })
            );
            setSetSharedInput(() => setPhoneNumber)
          }}
        />

        <p className={`${labelStyle} text-[18px] pb-[10px] `}>{t("label.email")}</p>
        <input
          type="text"
          value={emailAddress}
          placeholder={t("placeholder.email")}
          className={` w-[100%] shadow-lg ${inputStyle} rounded py-2 px-3`}
          onClick={() => {
            dispatch(setShowKeyboard(true));
            dispatch(
              setKeyboardConfiguration({
                type: "alphaNumeric",
                sharedInput: emailAddress,
                placeHolder: t("Common:keyboard.placeholder.customer.email"),
                // setSharedInput: x => {
                //   setEmailAddress(x);
                // }
              })
            );
            setSetSharedInput(() => setEmailAddress)
          }}
        />

        <div className="flex justify-center mt-[20px]">
          {/* <a href="/pos"> */}
          <button
            className={` ${submitButton} p-2 rounded flex justify-center items-center`}
            type="submit"
          >
            <span>{t("button.find.submit")}</span>
            <Spinner
              className="!w-3 !h-3 mx-1 text-white fill-blue-800"
              status={getBookingStatus}
            />
          </button>
          {/* </a> */}
        </div>
      </form>
    </div>
  );
};
export default FindBooking;
